<template>
  <div class="main">
    <section class="termsconditions py-5 ">
        <div class="container px-md-0 px-5 text-start">
            <h1 class="text-center pb-3">Terms & Conditions</h1>
            <p>By accessing our website at www.digitalspike.co and engaging with our services, you agree to comply with and be bound by the following terms and conditions ("Terms"). If you do not agree with these Terms, please do not use our website or services.</p>
            <div>
                <h3 class="py-3">General Work Terms and Client Responsibilities</h3>
                
                <p><b>Content Responsibility:</b></p>
                <p class="ps-md-2 list-text"> 
                    1. All content (text and multimedia) must be provided by the client before the commencement of work.<br>
                    2. Digital Spike Technologies is not responsible for data entry, web hosting, or custom artwork/graphics unless explicitly agreed upon and paid for by both parties.
                </p>

                <p><b>Data Back-up:</b></p>
                <p class="ps-md-2 list-text">
                    1. The client is responsible for backing up all content before Digital Spike Technologies undertakes any actions.<br>
                    2. Digital Spike Technologies is not liable for any loss or damage to existing data under any circumstances.
                </p>

                <p><b>Copyright and Permissions:</b></p>
                <p class="ps-md-2 list-text">
                    1. The client retains copyright to data, files, and graphic logos provided.<br>
                    2. The client is responsible for obtaining proper permissions and rights for third-party copyrighted materials.<br>
                    3. Digital Spike Technologies may request evidence of permissions and authorities.
                </p>
                <p><b>Client Review and Approval:</b></p>
                <p>The client has 7 days to provide feedback on shared work/outputs. Failure to respond within this period implies automatic acceptance by the client.</p>
                
                <p><b>Client-Induced Alterations:</b></p>
                <p>Digital Spike Technologies is not responsible for alterations made by the client or third parties once the website is installed/deployed.</p>
            </div>

            <div>
                <p><b>Payment Methods and Pricing:</b></p>
                <p class="ps-md-2 list-text">
                    1. Digital Spike Technologies accepts payments by cheque, cash, or bank transfers. <br>
                    2. Payment methods may be withdrawn or varied without prior notice. <br>
                    3. Prices are subject to change without prior notice. <br>
                </p>
            </div>

            <div>
                <p><b>Cancellation Fee:</b></p>
                <p class="ps-md-2 list-text">
                    1. A cancellation fee may be charged if the customer cancels the service before completion, equivalent to the work completed at the point of cancellation.<br>
                    2. Non-payment of cancellation fees and/or overdue amounts may result in legal action.
                </p>
            </div>

            <!-- Support and 3rd-Party -->
            <h5 class="py-3">Support and 3rd-Party</h5>
            <div class="">
                <p><b>Free Support and Charges:</b></p>
                <p class="ps-md-2 list-text">
                    1. Free support is provided for the first month after site launch.<br>
                    2. After the first month, support is charged based on our pricing packages.<br>
                    3. 3rd-Party support, products, and services are the client's responsibility, and Digital Spike Technologies may procure them on pre-payment.
                </p>

                <p><b>Upgrades and Guarantees:</b></p>
                <p class="ps-md-2 list-text">
                    1. Digital Spike Technologies does not guarantee the accuracy or performance of 3rd-Party products/services.<br>
                    2. Upgrades to 3rd-Party products/services may incur additional charges.<br>
                    3. Re-work, Enhancements/Add-ons, and Billing
                </p>
                
                <p><b>Change Management and Additional Billing:</b></p>
                <p class="ps-md-2 list-text">
                    1. Additional features beyond the scope of work will be billed through a Change Management process.<br>
                    2. Re-work, enhancements, and add-ons requested by the client post-approval will be additionally billed.
                </p>
            </div>
            <!-- Support and 3rd-Party -->

            <!-- Limitations -->
            <h5 class="py-3">Limitations of Liability</h5>
            <div class="">
                <p><b>Service Quality and Liability:</b></p>
                <p class="ps-md-2 list-text">
                    1. Digital Spike Technologies will provide the service with reasonable skill and care.<br>
                    2. Digital Spike Technologies excludes liability for loss or damage caused by inaccuracy, omission, delay, or error.<br>
                    3. Digital Spike Technologies is not liable for damages arising from third-party services.
                </p>
            </div>
            <!-- Limitations -->

            <!-- Approvals and Delivery -->
            <h5 class="py-3">Approvals and Delivery</h5>
            <div class="ps-md-2">
                <p><b>Stages and Ownership:</b><br></p>
                <p class="ps-md-2 list-text">
                    1. Work on the next stage begins after receiving approval and relevant payments for the previous stage.<br>
                    2. Code ownership transfers to the client after final payments.
                </p>
                <p><b>Website Upload and Publication:</b></p>
                <p class="ps-md-2 list-text">
                    1. The website is uploaded to the Digital Spike Technologies server for approval and transferred to the destination server upon client approval. <br>
                    2. Digital Spike Technologies reserves the right to delay uploading until full payment is received. <br>
                    3. Digital Spike Technologies may use the completed work for references unless notified otherwise.
                </p>
            </div>
            <!-- Approvals and Delivery -->

            <!-- Timescale -->
            <h5 class="py-3">Timescale</h5>
            <div class="">
                <p><b>Project Timescale:</b></p>
                <p class="ps-md-2 list-text">
                    1. Digital Spike Technologies aims to complete all services within the agreed timescale.<br>
                    2. Timescales may be extended due to circumstances beyond Digital Spike Technologies' control.
                </p>
            </div>
            <!-- Timescale -->

            <!-- Jurisdiction -->
            <h5 class="py-3">Jurisdiction</h5>
            <div class="">
                <b>Governing Law:</b><br><br>
                <p>These terms are governed by and interpreted in accordance with Indian Law.</p>
                <p>The courts of India have exclusive jurisdiction over any disputes arising from these Terms and Conditions.</p>
            </div>
            <!-- Jurisdiction -->
            
            <!-- Jurisdiction -->
            <h5 class="py-3">Severability</h5>
            <div class="">
                <b>Severability Clause:</b><br><br>
                <p>If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions remain unaffected.</p>
                <p>The invalid provision will be replaced with a valid one that aligns with the parties' intentions.</p>
            </div>
            <!-- Jurisdiction -->

            <h6 class="pt-3">By using our website and services, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</h6>
        </div>
    </section>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .main {
        overflow-x: hidden;
    }
    .list-text {
        line-height: 2em;
    }
</style>