<template>
  <div class="main">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main {
  overflow-x: hidden;
}
</style>