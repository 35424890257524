<template>
  <div class="main">
    <section class="py-3">
        <div class="container px-md-0 px-2 text-start">
            <h1 class="text-center pb-2">Privacy Policy</h1>
            <p>Digital Spike Technologies operates the www.digitalspike.co website, providing innovative solutions through our website, mobile application, and digital marketing services, collectively referred to as the "Service."</p>
            <p>This page aims to inform visitors about our policies concerning the collection, use, and disclosure of Personal Information when utilizing the Digital Spike Technologies website.</p>
            <p>By choosing to use our Service, you agree to the collection and use of information in accordance with this policy. The Personal Information we collect is used to enhance and improve the Service, and we commit to not using or sharing your information with anyone except as outlined in this Privacy Policy.</p>
            <p>The terms used herein have the same meanings as those defined in our Terms and Conditions, accessible at www.digitalspike.co, unless specifically defined in this Privacy Policy.</p>
            <p>
                <b>Information Collection and Use</b><br>
                To provide an enriched experience while using our Service, we may request certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information collected will be utilized to contact or identify you.
            </p>
            <p>
                <b>Log Data</b><br>
                When you access our Service, we gather information that your browser sends to us, known as Log Data. This Log Data may include details such as your computer’s Internet Protocol (“IP”) address, browser version, pages visited on our Service, the time and date of your visit, the duration spent on those pages, and other relevant statistics.
            </p>
            <p>
                <b>Cookies</b><br>
                Our website employs "cookies," which are small files containing data sent to your browser from the website you visit. These files are stored on your computer’s hard drive. The use of these "cookies" allows us to collect information and enhance our Service. You can choose to accept or refuse these cookies, and your browser can notify you when a cookie is being sent to your computer. Note that refusing cookies may limit your ability to use certain portions of our Service.
            </p>
            <div>
                <b>Service Providers</b><br>
                <p>In certain instances, we may engage third-party companies and individuals for the following reasons:</p>
                <ul>
                    <li>To facilitate our Service.</li>
                    <li>To provide the Service on our behalf.</li>
                    <li>To perform Service-related services.</li>
                    <li>To assist in analyzing how our Service is utilized.</li>
                    <li>It is important to note that these third parties have access to your Personal Information solely for the purpose of fulfilling tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</li>
                </ul>
            </div>
            <p>
                <b>Security</b><br>
                While we strive to use commercially acceptable means to protect your Personal Information, it is crucial to understand that no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee the absolute security and reliability of your information.
            </p>
            <p>
                <b>Links to Other Sites</b><br>
                Our Service may contain links to external sites. Please be aware that these sites are not operated by us. We strongly recommend reviewing the Privacy Policy of these websites, as we have no control over, and assume no responsibility for, their content, privacy policies, or practices.
            </p>
            <p>
                <b>Children’s Privacy</b><br>
                Our Services are not intended for individuals under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will promptly delete it from our servers. Parents or guardians who are aware of their child providing us with personal information should contact us so that we can take necessary actions.
            </p>
            <p>For any inquiries or concerns regarding our Privacy Policy, please contact us at contact@digitalspike.co.</p>
            <p>This Privacy Policy was last updated on [Date].</p>
        </div>
    </section>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main {
    overflow-x: hidden;
}
</style>