<template>
  <div class="main">

    <!-- hero-section -->
    <section class="hero pt-md-5 pt-3">
      <div class="container pt-md-5 px-md-5">
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 pt-md-5">
            <div class="pt-md-5">
              <h1 class="pt-3 pb-2 heading text-md-start text-center">Elevating Your <span style="color: rgba(115, 227, 134, 1) !important; font-weight: 700;">Brand</span><br> Through <span style="color: rgba(19, 89, 187, 1) !important; font-weight: 700;">Strategic<br> Marketing</span></h1>
              <div class="d-flex align-items-md-start justify-content-md-start align-items-center justify-content-center">
                <div class="download">
                <button type="button" class="btn btn-download btn-txt">Let's Plan Strategically</button>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <div>
              <img src="@/assets/home2/hero.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of hero-section -->

    <!-- our-process-section -->
    <section class="our-process py-5 d-none d-md-block">
      <div class="container py-md-5">
        <div class="pb-5">
          <h1 class="text-center heading">Our Process</h1>
          <p class="sub-heading text-center">Designing and developing a website & Mobile App comprises various stages.</p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div class="px-5">
            <img src="@/assets/ourprocess-digital.png" alt="" class="img-fluid process-img1">
          </div>
        </div>
      </div>
    </section>
    <section class="our-process py-5 d-md-none">
      <div class="container px-5 py-md-5">
        <div class="pb-5">
          <h1 class="text-center heading">Our Process</h1>
          <p class="sub-heading text-center">Designing and developing a website & Mobile App comprises various stages.</p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img src="@/assets/ourprocess-digital2.png" alt="" class="img-fluid process-img1 px-5">
          </div>
        </div>
      </div>
    </section>

    <!-- end of our-process-section -->

    <!-- service-section-1 -->
    <section class="service-section-1 pb-5">
      <div class="container px-5">
        <h1 class="text-center pt-5 service-header">Digital Marketing</h1>
        <p class="text-center pb-5">Digital Spike Technologies specializes in providing ROI-driven digital marketing services to all businesses.<br> We create tailor-made plans as we understand that every business is unique.
</p>
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 d-flex flex-column align-items-center justify-content-center mb-3">
            <div>
              <h1 class="pb-2 service-heading display-5 text-md-start text-center">Videos & Animations</h1>
              <p class="pe-5 sub-heading">Our Videos & Animations services breathe life into ideas, simplifying complexity through :</p>
              <div class="">
                <div class="row">
                  <div class="col-md-5">
                    <ul class="sub-heading">
                      <li>Animated Explainers</li>
                      <li>Product Animations</li>
                      <li>Corporate Presentations</li>
                      <div class="d-md-none">
                        <li>Branding Videos</li>
                        <li>Interactive Experiences</li>
                      </div>
                    </ul>
                  </div>
                  <div class="col-md-7 d-none d-md-block">
                    <ul class="sub-heading">
                      <li class="ps-md-0 ps-3">Branding Videos</li>
                      <li class="ps-md-0 ps-3">Interactive Experiences</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/s1.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of service-section-1 -->

    <!-- service-section-2 -->
    <section class="service-section-2 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/s2.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Email Marketing</h1>
              <p class="sub-heading text-justify">Reach your audience effectively with personalized campaigns, compelling content, and data-driven strategies. Maximize engagement, drive conversions, and elevate your brand's digital presence. Ready to transform your emails into powerful marketing tools? Contact us for tailored Email Marketing solutions today!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of service-section-2 -->

    <!-- service-section-3 -->
    <section class="service-section-3 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 mb-3">
            <div class="pt-md-5">
              <h1 class="pt-md-5 pb-2 service-heading display-5 text-md-start text-center">Social Media Marketing</h1>
              <p class="pe-5 sub-heading">Boost your brand's online presence with our expert services:</p>
              <div class="">
                <div class="row">
                  <div class="col-md-5">
                    <ul class="sub-heading">
                      <li>Targeted campaigns</li>
                      <li>Engaging Content Creation</li>
                      <li>Analytics-Driven Startegy</li>
                      <li>Audience Growth Tactics</li>
                      <div class="d-md-none">
                        <li>Proven Results</li>
                        <li>Customized Solutions</li>
                        <li>Expert Team</li>
                      </div>
                    </ul>
                  </div>
                  <div class="col-md-7 d-none d-md-block">
                    <ul class="sub-heading">
                      <li class="ps-md-0 ps-3">Proven Results</li>
                      <li class="ps-md-0 ps-3">Customized Solutions</li>
                      <li class="ps-md-0 ps-3">Expert Team</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/s3.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of service-section-3 -->

    <!-- service-section-4 -->
    <section class="service-section-4 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/s4.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Content Marketing</h1>
              <p class="sub-heading">Unlock your brand's potential with our comprehensive content marketing solutions. From compelling blog posts and engaging social media content to SEO-driven website copy, we craft narratives that resonate with your audience and drive results.Strategic Approach: Our content is crafted with a purpose—aligning with your goals and reaching your target audience effectively.<br><br>Creativity Meets Conversion: We blend creativity with data-driven strategies to ensure your content not only captivates but converts.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of service-section-4 -->

    <!-- service-section-5 -->
    <section class="service-section-5 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Social Media Optimization</h1>
              <p class="sub-heading">Unlock your brand's potential with our comprehensive content marketing solutions. From compelling blog posts and engaging social media content to SEO-driven website copy, we craft narratives that resonate with your audience and drive results.</p>
              <ul class="sub-heading">
                <li>Strategic Approach: Our content is crafted with a purpose—aligning with your goals and reaching your target audience effectively.</li>
                <li>Creativity Meets Conversion: We blend creativity with data-driven strategies to ensure your content not only captivates but converts.</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/s5.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of service-section-5 -->

    <!-- service-section-6 -->
    <section class="service-section-6 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/s6.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Search Engine Optimization</h1>
              <p class="sub-heading">we master the art of boosting your online presence through unparalleled Search Engine Optimization (SEO) services.</p>
              <ul class="sub-heading">
                <li>Boost website visibility</li>
                <li>Increase organic traffic</li>
                <li>Improve search rankings</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of service-section-6 -->

    <!-- service-section-7 -->
    <section class="service-section-7 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Search Engine Marketing</h1>
              <p class="sub-heading">we excel in Search Engine Marketing (SEM) to supercharge your online presence. Our experts optimize your digital strategy for maximum visibility, driving targeted traffic and ensuring your brand stands out. Elevate your online success with our SEM solutions—precision, impact, results.</p>
              <p class="sub-heading">Boost your online visibility with our SEM services:</p>
              <ul class="sub-heading">
                <li>Precision-targeted keyword strategies</li>
                <li>High-impact ad campaigns</li>
                <li>Data-driven optimization</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/s7.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of service-section-7 -->

  </div>
</template>

<script>
export default {
  name:"digitalmarketing",
}
</script>

<style scoped>
  .main {
    overflow-x: hidden;
  }
/* navbar-section */
#dst-logo{
  width: 160px;
  height: 50px;
}
nav .nav-link {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
nav a.router-link-active {
    color: rgba(39, 133, 255, 1);
}
nav .download {
    background: rgba(13, 31, 15, 1);
}
nav .btn-txt {
    background: linear-gradient(
    90deg,
    rgba(51, 146, 238, 1) 2%,
    rgba(115, 227, 133, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
nav .btn-txt:hover {
    background: linear-gradient(
    90deg,
    rgba(115, 227, 133, 1) 2%,
    rgba(51, 146, 238, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
@media (max-width: 767.98px) {
    .d{
        padding-top: 14px;
    }
}
/* sub-navbar-section */
.subnav-section{
  margin: 0px 60px;
}
.subnav-class a{
    color: rgba(1, 13, 38, 1);
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
.subnav-class header .tabs{
    display: flex;
    overflow-y: auto;
}

.subnav-class header .tabs a img{
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.subnav-class header .tabs a{
    display: block;
    padding: 0px 13px 13px 30px;
    font-weight: 900;
    outline: none;
    white-space: nowrap;
    text-decoration: none;
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
    cursor: pointer;
}

.subnav-class .tabs::-webkit-scrollbar {
    height: 0;
}

.subnav-class header .tabs a.router-link-active{
   
    border-bottom: 2px solid #73E386;
}
@media screen and (max-width:992px) {
    .subnav-section{
  margin: 0px 0px;
}
}
/* hero-section */
.hero .download {
    background: rgba(13, 31, 15, 1);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero .btn-txt {
    background: linear-gradient(
    90deg,
    rgba(51, 146, 238, 1) 2%,
    rgba(115, 227, 133, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
.hero .btn-txt:hover {
    background: linear-gradient(
    90deg,
    rgba(115, 227, 133, 1) 2%,
    rgba(51, 146, 238, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
.hero .heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
}
.hero {
    background-image: url("~@/assets/home2/hero-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
@media (max-width: 767.98px) {
    .hero {
        background-position: left !important;
    }
}
/* process-section */
.our-process .sub-heading {
  color : rgba(114, 114, 114, 1);
  font-family: 'Montserrat';
  font-weight: 400;
}
.our-process .heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
/* service-section */
.service-header {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
.service-heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
.sub-heading {
  color: rgba(61, 70, 80, 1);
  font-family: 'Mulish';
  font-weight: 400;
}
.service-section-1 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-2 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-3 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-4 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-5 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-6 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-7 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
/* faq-section */
.faq-design {
    background-image: url("~@/assets/faq-bg.png"), url("~@/assets/home2/faqbg.png");
    background-repeat: no-repeat;
    background-position: left center, right top !important;
}
.faq-design .question {
  font-weight: 700;
  cursor: pointer;
}
.faq-design .heading {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.faq-design .faq-box:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.far.fa-plus-square,
.far.fa-minus-square {
  color: #2785ff;
}
.faq-arrow {
    max-width: 200px;
}
.faq-design .question {
    color: rgba(16, 16, 16, 1) !important;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: larger;
}
.faq-design .collapse {
    color: rgba(16, 16, 16, 1) !important;
    font-family: 'Montserrat';
    font-weight: 400;
}
.faq-design .faq-text {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: 'Open Sans';
}
@media (max-width: 767.98px) {
    .faq-box{
        padding: 0px 16px;
    }
}
/* contact-section */
.contact-section {
    background-image: url("~@/assets/contact-bg.png");
    background-repeat: no-repeat;
    background-position: right !important;
    color: #000;
    font-family: "Exo", sans-serif;
}
.contact-section .contact-links {
  font-weight: 600;
}
.contact-section .contact-img {
    max-width: 300px;
}
.contact-section .form-cover {
    width: 86%;
}
.contact-section .form-control {
  font-size: 15px !important;
  color: #858585;
  font-family: Mulish;
  border: 1.5px solid #d1d3db80;
}
.contact-section .form-control:focus {
  box-shadow: none;
}
.contact-section .submitBtn {
    background: #2289fe;
    color: rgba(255, 255, 255, 1);
    border: none;
    font-family: 'Montserrat';
    font-weight: 600;
}
.contact-section .heading {
    color: rgba(16, 16, 16, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.contact-section label {
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(18, 18, 18, 1);
}
.contact-section input {
    font-family: 'Mulish';
    font-weight: 400;
    color: rgba(133, 133, 133, 1);
}
/* footer-section */
.footer .links-header {
    color: rgba(39, 133, 255, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.footer .links-item {
    color: rgba(1, 13, 38, 1);
    font-family: 'Mulish';
    font-weight: 500;
}
.footer .copyright {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
    font-weight: 400;
}
.footer .copyright-conditions {
    color: rgba(0, 52, 109, 1);
    font-family: 'Montserrat';
    font-weight: 500;
}
@media (max-width: 767.98px) { 
    .contact-section {
        background-position: center !important;
    }
}
</style>