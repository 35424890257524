<template>
    <div class="main">

        <section class="contact-section py-5">
      <div class="container pb-md-5">
        <h1 class="d-none d-md-block heading text-center pb-5">
                Let’s Achieve Something <br>Great Together!...
              </h1>
              <h1 class="text-center d-md-none heading">
                Let’s Achieve Something <br>Great Together!...
              </h1>
              
        <div class="row">
              <div
            class="col-md-6 d-flex flex-column align-items-center justify-content-center con"
          >
            <div class="contact-us-img">
              
              <img
                src="@/assets/contact-img.png"
                alt=""
                class="contact-img img-fluid"
              />
            </div>
          </div>
          <div
            class="col-md-6 d-flex align-items-center justify-content-center"
          >
            <div class="form-cover">
              <form v-on:submit.prevent="onSubmit()" id='myForms'>
                <fieldset>
                  <div class="text mb-3">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Name</label
                    >
                    <input
                    v-model="username"
                      type="text"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your name"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <div class="text mb-3">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Email Address</label
                    >
                    <input
                    v-model="email"
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your email address"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <div class="text mb-3">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Mobile Number</label
                    >
                    <input
                    v-model="phone"
                      type="text"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your mobile number"
                      @keypress="restrictChars($event)"
                      maxlength="10"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <div class="text mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label"
                      >Message</label
                    >
                    <textarea
                      v-model="message"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Write a message"
                      autocomplete="off"
                    ></textarea>
                  </div>
                  <div class="pt-2">
                    <button
                     
                      type="submit"
                      class="submitBtn btn w-100 text-uppercase"
                    >
                      Submit
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <!-- end of contact-section -->

         <!-- competencies-section -->
    <section class="competencies-section py-5">
        <div class="container-lg container-fluid py-5">
            <div class="pb-3">
                <h1 class="text-center heading">Reach Out Us</h1>
                <p class="text-center sub-heading">
                   
                   </p>
            </div>
            <div class="d-flex flex-row flex-wrap gap-4 align-items-center justify-content-center px-3">
                <div class="card">
                    <div class="card-body">
                      <h5 class="card-title pt-2 px-2">mysore</h5>
                        <div class="card-text">
                         <div class="edit py-2"><img src="@/assets/loc.png" class="px-2 pt-2" style="width:35px; hight:35px;"><b class="px-1">#392 1st Floor, Jnana Marga, Siddartha Nagar<br> 1st Stage, Mysore -570011</b></div>
                          <div class="edit py-2"><img src="@/assets/cont.png"  style="width:30px; hight:30px;"><b class="px-1">+91 90089 83396</b></div>
                           <div class="edit py-2"><img src="@/assets/mailbox.png" class="px-2" style="width:35px; hight:35px;"><b class="px-1">hello@digitalspike.co</b></div>
                          </div>
                    </div>
                </div>
                <!-- <div class="card card">
                    <div class="card-body"> -->
                         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3898.176439728581!2d76.67637180978738!3d12.30390198790324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf7031d4faee8d%3A0xab72f9bd46213294!2s52a%2C%20Vinaya%20Marga%2C%20Siddhartha%20Nagar%2C%20Siddhartha%20Layout%2C%20Mysuru%2C%20Karnataka%20570011!5e0!3m2!1sen!2sin!4v1703245733518!5m2!1sen!2sin" width="300px" height="250px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>              
                    <!-- </div>
                </div> -->
                <div class="card">
                     <div class="card-body">
                      <h5 class="card-title pt-2 px-2">Banglore</h5>
                        <div class="card-text">
                         <div class="edit py-2"><img src="@/assets/loc.png" class="px-2 pt-2" style="width:35px; hight:35px;"><b class="px-1">MyHQ #1207/343 & 1207/1/343/1,<br> 9th Main, 7th Sector, HSR<br> Layout, Banglore - 560102</b></div>
                          <div class="edit py-2"><img src="@/assets/cont.png"  style="width:30px; hight:30px;"><b class="px-1">+91 90089 83396</b></div>
                           <div class="edit py-2"><img src="@/assets/mailbox.png" class="px-2" style="width:35px; hight:35px;"><b class="px-1">hello@digitalspike.co</b></div>
                          </div>
                    </div>
                </div>
                <!-- <div class="card"> -->
                    <!-- <div class="card-body"> -->
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15555.726438167267!2d77.62549996376032!3d12.912117104505505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae149adbbe1cc3%3A0x637c9d4cccaab6ef!2sStreamger%20Entertainment%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1703245553161!5m2!1sen!2sin" width="300px" height="250px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <!-- </div> -->
                <!-- </div> -->
                <div class="card">
                     <div class="card-body">
                      <h5 class="card-title pt-2 px-2">Dubai</h5>
                        <div class="card-text">
                         <div class="edit py-2"><img src="@/assets/loc.png" class="px-2 pt-2" style="width:35px; hight:35px;"><b class="px-1">#302, Trafalgar TowerCBD <br>7, International city,Dubai,<br> UAE.</b></div>
                          <div class="edit py-2"><img src="@/assets/cont.png"  style="width:30px; hight:30px;"><b class="px-1">+91 90089 83396</b></div>
                           <div class="edit py-2"><img src="@/assets/mailbox.png" class="px-2" style="width:35px; hight:35px;"><b class="px-1">hello@digitalspike.co</b></div>
                          </div>
                    </div>
                </div>
                <!-- <div class="card">
                    <div class="card-body"> -->
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.166359147939!2d55.40253948486549!3d25.163860014303648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f614c797e81d5%3A0x5ebfb4bfab0386f8!2sInternational%20City%2C%20Trafalgar%20Tower%201!5e0!3m2!1sen!2sin!4v1703245257470!5m2!1sen!2sin" width="300px" height="250px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <!-- </div>
                </div> -->
            </div>
        </div>
    </section>
    <!-- end of competencies-section -->
    </div>
</template>

<script>

import axios from 'axios';
import Swal from "sweetalert2";

    export default {
        name:"InitiateCollaboration",
        data() {
    return {
      username: '',
      email: '',
      phone: '',
      message: '',
      
    };
  },
 
        methods: {
          restrictChars: function($event) {
    if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode))) {
        return true
    } else {
        $event.preventDefault();
    }
},
    onSubmit() {
                axios.post('https://digitalspike.co/enquiry/enquiry.php',{
                    username: this.username,
                    email: this.email,
                    phone: this.phone,
                    message: this.message,
                },
                { 
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                    }
                })
                .then((response) => {
          console.log(response);
          $("#myForms")[0].reset();
          $("#exampleModals").modal("hide");
        })
        .then(() => {
          $("#email-modal").modal("show");
          setTimeout(function () {
            $("#email-modal").modal("hide");
          }, 2000);
        })
                .catch(error=>{
                    if (error.response) {
                    console.log('Server responded with status code:', error.response.status);
                    console.log('Response data:', error.response.data);
                    } else if (error.request) {
                    console.log('No response received:', error.request);
                    } else {
                    console.log('Error creating request:', error.message);
                    }
                })
            },
    toggleCollapse(index) {
      // Close all FAQs
      this.faqs.forEach((faq, i) => {
        if (i !== index) {
          faq.expanded = false;
        }
      });

      // Toggle the selected FAQ
      this.faqs[index].expanded = !this.faqs[index].expanded;
    },
    slide(direction) {
    const tabsContainer = this.$refs.tabsContainer;

    if (tabsContainer) {
      const scrollAmount = 300; // Adjust the scroll amount as needed

      if (direction === 'left') {
        tabsContainer.scrollLeft -= scrollAmount;
      } else if (direction === 'right') {
        tabsContainer.scrollLeft += scrollAmount;
      }
    }
  },
  
  },
    }
</script>

<style scoped>
.main {
  overflow-x: hidden;
}
/* contact-section */
.contact-section {
    background-image: url("~@/assets/home2/hero-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
@media (max-width: 767.98px) {
    .hero {
        background-position: left !important;
    }
}
.contact-section .contact-links {
  font-weight: 600;
}
.contact-section .contact-img {
    max-width: 300px;
}
.contact-section .form-cover {
    width: 86%;
}
.contact-section .form-control {
  font-size: 15px !important;
  color: #858585;
  font-family: Mulish;
  border: 1.5px solid #d1d3db80;
}
.contact-section .form-control:focus {
  box-shadow: none;
}
.contact-section .submitBtn {
    background: #2289fe;
    color: rgba(255, 255, 255, 1);
    border: none;
    font-family: 'Montserrat';
    font-weight: 600;
}
.contact-section .submitBtn:hover {
    background: #2289fe;
    color: rgba(255, 255, 255, 1);
    border: none;
    font-family: 'Montserrat';
    font-weight: 600;
}
.contact-section .heading {
    color: rgba(16, 16, 16, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.contact-section label {
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(18, 18, 18, 1);
}
.contact-section input {
    font-family: 'Mulish';
    font-weight: 400;
    color: rgba(133, 133, 133, 1);
}
.competencies-section {
    background-image: url("~@/assets/faq-bg.png"), url("~@/assets/home2/faqbg.png");
    background-repeat: no-repeat;
    background-position: left center, right top !important;
}
.edit {
    display: flex;
    align-items: flex-start;
   font-family: Montserrat;
  }

.competencies-section .heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;  
} 
.competencies-section .card {
    width: 300px;
    height: 250px;
     border-radius: 20px; 
    border: 1px solid #DBE2FF;
}

.competencies-section .card-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
   background: linear-gradient(90.33deg, #2785FF 0.28%, #73E386 10.15%);
    background-clip: text;
    -webkit-background-clip: text; /* For Safari/Chrome */
    color: transparent;
}
iframe {
  border-radius: 20px;
}
</style>