<template>
  <div class="main">

    <div
      class="modal fade"
      id="email-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div>
              <section
                class="clock p-1 d-flex align-items-center justify-content-center"
              >
                <div class="p-4" style="width: 25rem; border-radius: 20px">
                  <div
                    class="card-body d-flex flex-column align-items-center justify-content-center"
                  >
                    <div class="py-3 px-2">
                      <img
                        src="@/assets/Group 136.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <h3 class="verify-btn py-2 lh-sm mb-0 text-center">
                      Grateful for Your Contact!
                    </h3>
                    <div class="pb-2 color">
                      <p class="text-center">
                        Thank You for Reaching Out to Us.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- hero-section -->
    <section class="hero">
        <div class="container">
            <div class="row">
                <div class="col-md-6 d-flex align-items-center justify-content-center">
                    <div>
                        <img src="@/assets/aboutlogo.png" alt="" class="img-fluid hero-img"/>
                    </div>
                </div>
                <div class="col-md-6 pt-md-5">
                    <div class="pt-md-5 d-flex flex-column">
                        <div class="float-start pt-5">
                        <img src="@/assets/smallStar.svg" alt="" class="star-top img-fluid">
                        </div>
                        <h1 class="pt-3 heading text-md-start text-center">Elevate Your <span style="color: rgba(115, 227, 134, 1) !important; font-weight: 700;">Possibilities</span><br> with <span style="color: rgba(39, 133, 255, 1) !important; font-weight: 700;">Innovative</span> Software<br> Solutions</h1>
                            <div class="pt-2">
                            <div class="newsletter js-rollover d-flex flex-column align-items-md-start justify-content-md-start align-items-center justify-content-center">
                                <form class="newsletter-form" name="submit-to-google-sheet" id="newsform">
                                    <input type="email" name="Emails" placeholder="Enter your email" autocomplete="off" required>
                                    <button type="submit" class="button">Let's Talk</button>
                                </form>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="float-end">
                                                <img src="@/assets/bigStar.svg" alt="" class="star-bottom img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-md-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </section>
    <!-- end of hero-section -->

    <!--discription section-->
    <section class="discription py-5">
        <div class="container px-md-0 px-5">
            <div class="row">
                <div class="col-lg-3 col-md-4 text-center mx-auto mx-md-0 pb-5 ">
                    <img src="@/assets/discription-img.png" alt="" class="desc-img">
                </div>
                <div class="col-lg-9 col-md-8 ">
                    <p class="text-justify" style="font-family: Mulish; font-weight: 400; color: rgba(133, 133, 133, 1);">Digital Spike Technologies was founded by a team of proficient people on the knowledge and skill that gained over the last one and half decades in the arena of the digital era and its
                        formation over the years.
                        We provide digital marketing services to small businesses, start-ups, and to all types of
                        businesses. Our services are not limited to any particular sector, brand, or product. Our
                        goal is to give robust sales and branding growth to each and every business that partners with us for their digital media requirements.
                        The One Thing that differentiates us from the rest of the digital marketing companies
                        is that we study the business model of our clients in-depth and with the fulfillment of
                        their requirements we also suggest to them innovative and new marketing
                        strategies.<br>

                        <br>We develop growth-oriented online marketing campaigns that make a
                        positive impact on businesses.
                        At Digital Spike Technologies, we follow proven strategies and methods to
                        show you real results. Our digital marketing team goes far beyond what is
                        required to guarantee that our digital marketing campaigns do not just spike
                        up your business but also upkeep sustainable success for the long term.
                        At Digital Spike Technologies, we follow proven strategies and methods
                        to show you real results. Our digital marketing team goes far beyond
                        what is required to guarantee that our digital marketing campaigns do
                        not just spike up your business but also upkeep sustainable success
                        for the long term.
                        We believe that every “Business has only two basic functions:
                        marketing and innovation” and ensure we keep innovating our
                        digital marketing strategies every day.
                    </p>

                </div>
            </div>
        </div>
    </section>
    <!--end of discription section-->

    <!-- choose-us-section -->
    <section class="why-section pt-3 pb-5">
        <div class="container">
        <div class="row">
            <div class="col-md-6 d-flex align-items-md-center justify-content-md-center">
                <img
                    src="@/assets/about-choose.png"
                    alt=""
                    class="img-fluid about-img"
                />
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center">
                <div class="px-lg-5 px-2">
                    <h1 class="why-header mb-4 text-start display-6">Why Choose Us</h1>
                    <div class="d-flex">
                        <div class="mchooseCol2">
                            <div class="d-flex mb-4">
                                <div>
                                    <img class="star-img" src="@/assets/starList.svg" alt="" />
                                </div>
                                <div class="ps-2">
                                    <h5 class="sub-header">Tailored Solutions</h5>
                                    <span class="txt">Co-create a focused project brief for your website and app, ensuring seamless design and development alignment with your business vision.</span>
                                </div>
                            </div>
                            <div class="d-flex mb-4">
                                <div>
                                    <img class="star-img" src="@/assets/starList.svg" alt="" />
                                </div>
                                <div class="ps-2">
                                    <h5 class="sub-header">Consistent Design Brilliance</h5>
                                    <span class="txt">Experience clear, engaging design that maintains brand consistency across digital channels, culminating in a flawless user journey.</span>
                                </div>
                            </div>
                            <div class="d-flex mb-4">
                                <div>
                                    <img class="star-img" src="@/assets/starList.svg" alt="" />
                                </div>
                                <div class="ps-2">
                                    <h5 class="sub-header">Efficient Launch Preparation</h5>
                                    <span class="txt">Trust our QA excellence for bug-free delivery, and let our strategists orchestrate an optimal 30-day launch campaign for your new digital presence.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    <!-- end of choose-us-section -->

    <!-- competencies-section -->
    <section class="competencies-section">
        <div class="container-lg container-fluid py-5">
            <div class="pb-3">
                <h1 class="text-center heading">Our Core Competencies</h1>
                <p class="text-center sub-heading">Explore the limitless possibilities with Digital Spike Technology – where<br> expertise meets innovation.</p>
            </div>
            <div class="d-flex flex-row flex-wrap gap-4 align-items-center justify-content-center">
                <div class="card">
                    <div class="card-body">
                        <div class="card-header-img d-flex -align-items-center justify-content-center">
                            <img src="@/assets/comp1.svg" alt="" class="img-fluid comp">
                        </div>
                        <h6 class="card-title pt-2">Strategic Guidance</h6>
                        <p class="card-text">Leverage our strategic insight to align your software initiatives with your business objectives. We offer tailored advice to help you make informed decisions that contribute to your overall success.</p>
                    </div>
                </div>
                <div class="card card">
                    <div class="card-body">
                        <div class="card-header-img d-flex -align-items-center justify-content-center">
                            <img src="@/assets/comp22.svg" alt="" class="img-fluid comp">
                        </div>
                        <h6 class="card-title pt-2">Cutting-Edge Technologies</h6>
                        <p class="card-text">Stay ahead of the curve with our in-depth knowledge of the latest technologies. From AI and machine learning to blockchain and cloud solutions, we guide you in adopting the right tools for your specific needs.</p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="card-header-img d-flex -align-items-center justify-content-center">
                            <img src="@/assets/comp3.svg" alt="" class="img-fluid comp">
                        </div>
                        <h6 class="card-title pt-2">Custom Development Solutions</h6>
                        <p class="card-text">Our team excels in crafting bespoke software solutions. Whether you're in need of a scalable web application, a mobile app, or a comprehensive enterprise system, our experts ensure your software aligns perfectly with your requirements.</p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="card-header-img d-flex -align-items-center justify-content-center">
                            <img src="@/assets/comp4.svg" alt="" class="img-fluid comp">
                        </div>
                        <h6 class="card-title pt-2">Security and Compliance</h6>
                        <p class="card-text">Trust is paramount in the digital age. We prioritize security and compliance in every aspect of our consulting services, ensuring your software meets industry standards and safeguards your data.</p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="card-header-img d-flex -align-items-center justify-content-center">
                            <img src="@/assets/comp5.svg" alt="" class="img-fluid comp">
                        </div>
                        <h6 class="card-title pt-2">Agile Methodologie</h6>
                        <p class="card-text">Embrace agility with our iterative and collaborative approach to development. We implement Agile methodologies to enhance efficiency, foster innovation, and adapt to evolving project needs seamlessly.</p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="card-header-img d-flex -align-items-center justify-content-center">
                            <img src="@/assets/comp6.svg" alt="" class="img-fluid comp">
                        </div>
                        <h6 class="card-title pt-2">User-Centric Design</h6>
                        <p class="card-text">Elevate the user experience with our focus on user-centric design principles. From wireframing to prototyping, we prioritize creating software interfaces that are intuitive, engaging, and aligned with your target audience.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of competencies-section -->

   <!-- client-section -->
   <section class="client-section pt-5 pb-md-5">
      <div class="container py-md-5">
        <div>
          <h4 class="" style="font-style: popines;">EXCELLENT <span class="text-warning"><svg
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill"
                viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-star-fill" viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-star-fill" viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-star-fill" viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-star-fill" viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg></span> </h4>
        </div>
        <div>
          <h4> 80 riviews on <span><img src="@/assets/blogs/logo-one.svg" class="img-fluid" style="width:6%"
                alt=""></span> </h4>

        </div>

        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center">
            <div>


              <div class="text-lg-start text-center">
                <img class="w-25" src="@/assets/bluequotes.svg" alt="" />
              </div>
              <h1 class="text-lg-start text-center py-3 d-none d-lg-block heading">What Our <br>Clients <br>Say</h1>



              <h1 class="text-center d-lg-none heading py-3">What Our Clients Say</h1>
              <p class="text-lg-start text-center sub-txt">Turn on the power of our IT solutions through inspiring client
                testimonials. From enhanced efficiency to remarkable growth, witness the impact of partnering with us on
                your journey to technological excellence. Your success story starts here.</p>
            </div>
          </div>
          <div class="col-md-4 d-flex" id="mainGate">
            <div class="slider">
              <div class="slide-track">

                <div class="slide" v-for="list in list" :key="list.id">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <img class="double" src="@/assets/double.svg" alt="" />
                      </div>
                      <p class="pt-2 txt">{{ list.review }}
                      </p>
                    </div>
                    <div class="cards-footer p-2">
                      <div class="row">
                        <div class=" text-center  col-2 py-4">
                          <img src="@/assets/blogs/google.png" class="  img-fluid" style="width: 50%;" > 
                        </div>
                        <div class="col-10">
                          <div class="d-flex flex-column  align-items-start justify-content-center">
                            <div class=" pt-3 name">{{ list.name }}</div>
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                              class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                              class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                              class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                              class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                              class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg></div>

                          </div>


                          

                        </div>
                        <div class="col-">
                          
                        </div>

                      </div>



                    </div>
                  </div>
                </div>
                <!-- <div class="slide">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <img class="double" src="@/assets/double.svg" alt="" />
                                        </div>
                                        <p class="pt-2 txt">Lorem ipsum dolor sit amet consectetur. Hac sociis
                                            viverra tincidunt risus est. Nisl varius sit velit sit.
                                            Id lorem eget maecenas ut nibh sed. Sed consequat odio
                                            proin odio velit a at at scelerisque. Aliquam purus
                                            facilisis cras justo cursus cursus suspendisse purus.
                                        </p>
                                        
                                        
                                    </div>
                                    <div class="cards-footer p-2">
                                        <div class="d-flex">
                                            <div class="float-start">
                                                <img
                                        class="clientImg pb-2 ps-1"
                                        src="@/assets/clientimage.svg"
                                        alt=""
                                    />
                                            </div>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <p class="ps-2 pt-3 name">User Name Goes Here</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->



              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex d-none d-md-block" id="mainGate">
            <div class="slider">
              <div class="slide-track2">/
                <div class="slide" v-for="list in list" :key="list.id">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <img class="double" src="@/assets/double.svg" alt="" />
                      </div>
                      <p class="pt-2 txt">{{ list.review }}
                      </p>
                    </div>
                    <div class="cards-footer p-2">
                      <div class="row">
                        <div class=" text-center  col-2 py-4">
                          <img src="@/assets/blogs/google.png" class="  img-fluid" style="width: 45%;" > 
                        </div>

                        <div class="col-10">
                          <div class="d-flex flex-column align-items-start justify-content-start">
                            <div class="pt-3 name">{{ list.name }}</div>

                            <div><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg></div>

                          </div>




                        </div>
                        <div class="col-6">

                        </div>


                      </div>



                    </div>
                  </div>
                </div>
                <!-- <div class="slide" v-for="list in list" :key="list.id">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <img class="double" src="@/assets/double.svg" alt="" />
                      </div>
                      <p class="pt-2 txt">{{ list.review }}
                      </p>
                    </div>
                    <div class="cards-footer p-2">
                      <div class="d-flex">
                        <div class="float-start">
                          <img class="clientImg pb-2 ps-1" src={{image}} alt="googleimage" />
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                          <p class="ps-2 pt-3 name">{{ list.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of client-section -->

    <!-- solutions-section -->
    <section class="solutions-section py-md-5">
        <div class="container py-md-5">
            <h1 class="text-center heading">Beyond Solutions</h1>
            <p class="pb-3 text-center sub-heading">We don’t do marketing - we just love our clients</p>
            <div class="row px-lg-5 py-md-5">
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/ezye.jpg" alt="" class="img-fluid sol-img1">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/syora.jpg" alt="" class="img-fluid sol-img2">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/Amywood.jpg" alt="" class="img-fluid sol-img3">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/neo.jpg" alt="" class="img-fluid sol-img4">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/Bechdo.jpg" alt="" class="img-fluid sol-img5">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/jobsu.jpg" alt="" class="img-fluid sol-img6">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/Style.jpg" alt="" class="img-fluid sol-img7">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/cropped.jpg" alt="" class="img-fluid sol-img8">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/FJ.jpg" alt="" class="img-fluid sol-img9">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/face.jpg" alt="" class="img-fluid sol-img10">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/Thakur.jpg" alt="" class="img-fluid sol-img11">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/Seqronics.jpg" alt="" class="img-fluid sol-img12">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/Kaaya.jpg" alt="" class="img-fluid sol-img13">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/dream.jpg" alt="" class="img-fluid sol-img14">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/solray.jpg" alt="" class="img-fluid sol-img15">
                    </div>
                </div>
                <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
                    <div class="card">
                        <img src="@/assets/myhome.jpg" alt="" class="img-fluid sol-img16">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of solutions-section -->

  </div>
</template>

<script>
import Swal from "sweetalert2";

import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      list: []
    }
  },
  mounted() {
    var swiper = new Swiper(".card_slider", {
      slidesPerView: 2,
      spaceBetween: 30,
      loop: false,
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 1,
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      },
    });
    swiper.on("slideChange", function () {
      // Check if it's the last slide
      if (swiper.isEnd) {
        $(".swiper-next").addClass("disabled");
      } else {
        $(".swiper-next").removeClass("disabled");
      }

      // Check if it's the first slide
      if (swiper.isBeginning) {
        $(".swiper-prev").addClass("disabled");
      } else {
        $(".swiper-prev").removeClass("disabled");
      }
    });

    var swiper2 = new Swiper(".card_slider2", {
      slidesPerView: 2,
      spaceBetween: 30,
      loop: false,
      autoplay: {
        delay: 2000,
      },
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 1,
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      },
    });
    swiper2.on("slideChange", function () {
      // Check if it's the last slide
      if (swiper.isEnd) {
        $(".swiper-next").addClass("disabled");
      } else {
        $(".swiper-next").removeClass("disabled");
      }

      // Check if it's the first slide
      if (swiper.isBeginning) {
        $(".swiper-prev").addClass("disabled");
      } else {
        $(".swiper-prev").removeClass("disabled");
      }
    });

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbzYiEqkkc1Nd-DlDPKpUaGTpYklzUSm16NtiH_FSBYsCwYKyWozMPJJRQZArp0XEf9atA/exec";
    const form = document.forms["submit-to-google-sheet"];

    form.addEventListener("submit", (e) => {
      e.preventDefault();
      fetch(scriptURL, { method: "POST", body: new FormData(form) })
        .then(
          (response) => console.log("Success!", response),
          $("#newsform")[0].reset(),
          $("#email-modal").modal("show"),
          $(".modal-backdrop").hide(),

          setTimeout(function () {
            $("#email-modal").modal("hide");
          }, 2000)
        )
        .catch((error) => console.error("Error!", error.message));
    });
    axios.get('https://digitalspike.co/digitalReviews/review.php')
      .then((response) => {
        this.list = response.data; // Assuming 'list' is a data property in your Vue component
        console.log(this.list);
        console.log(response);
      }).catch((error) => {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          if (error.response.status == 400) {
            Swal.fire({
              icon: "error",
              title: "User Not Found!",
              showConfirmButton: false,
              timer: 2000,
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "Invalid Credentials!",
              showConfirmButton: false,
              timer: 2000,
            })
          }
          console.log("Response data:", error.response.data)
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });

  }
}

</script>

<style scoped>
.main {
    overflow-x : hidden;
}
/* navbar-section */
.logo{
  width: 160px;
  height: 50px;
}
nav .nav-link {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
nav .active {
    color: rgba(39, 133, 255, 1) !important;
}
nav .download {
    background: rgba(13, 31, 15, 1);
}
nav .btn-txt {
    background: linear-gradient(
    90deg,
    rgba(51, 146, 238, 1) 2%,
    rgba(115, 227, 133, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
nav .btn-txt:hover {
    background: linear-gradient(
    90deg,
    rgba(115, 227, 133, 1) 2%,
    rgba(51, 146, 238, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
@media (max-width: 767.98px) {
    .d{
        padding-top: 14px;
    }
}
/* sub-navbar-section */
.subnav-section{
  margin: 0px 60px;
}
.subnav-class a{
    color: rgba(1, 13, 38, 1);
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
.subnav-class header .tabs{
    display: flex;
    overflow-y: auto;
}

.subnav-class header .tabs a img{
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.subnav-class header .tabs a{
    display: block;
    padding: 0px 13px 13px 30px;
    font-weight: 900;
    outline: none;
    white-space: nowrap;
    text-decoration: none;
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
    cursor: pointer;
}

.subnav-class .tabs::-webkit-scrollbar {
    height: 0;
}

.subnav-class header .tabs a.router-link-active{
   
    border-bottom: 2px solid #73E386;
}
@media screen and (max-width:992px) {
    .subnav-section{
  margin: 0px 0px;
}
}
/* hero-section */

.hero {
    background-image: url("~@/assets/hero.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.hero .heading {
   color: rgba(1, 13, 38, 1);
   font-family: 'Montserrat';
}

.star-top {
    max-width: 80px;
}

.newsletter {
    position: relative;
    /* display: flex;
    align-items: start;
    justify-content: start; */
}
.newsletter .newsletter-form {
    position: relative;
    max-width: 355px;
    margin-top: 23px;
    z-index: 999;
}
.newsletter .newsletter-form input {
    height: 55px;
    width: 100%;
    padding: 20px 135px 17px 20px;
    border: none;
    border-radius: 10px;
    outline: none;
    background-color: #ffffff;
    color: rgba(133, 133, 133, 1);
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.newsletter .newsletter-form button {
    width: 120px;
    height: 38px;
    border-radius: 5px;
    background-color: #000;
    font-size: .875rem;
    line-height: 1;
    position: absolute;
    right: 9px;
    bottom: 9px;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0px;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.since-text {
    color: rgba(16, 16, 16, 1);
    font-family: 'Mulish';
    font-weight: 600;
    letter-spacing: 1.2px;
}
#smallStar{
  width: 60px;
  height: 70px;
  position: absolute;
  top: 210px;
  left: 30px;
   z-index: -1;
}
#bigStar {
  width: 85px;
  height: 95px;
  position: absolute;
  bottom: 240px;
  right: 250px;
  z-index: -1;
}
.box-card {
    width: 350px;
    height: 250px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    border-radius: 10px;
    border: none;
}
.since-header {
    color: #73e386;
    font-family: 'Montserrat';
    font-weight: 700;
}
.project-header {
    color: #2785ff;
    font-family: 'Montserrat';
    font-weight: 700;
}
.employees-header {
    color: #ee7e1b;
    font-family: 'Montserrat';
    font-weight: 700;
}
@media (max-width: 768px) {
#smallStar{
  width: 60px;
  height: 70px;
  position: absolute;
  top: 20px;
  left: 110px;
}
#bigStar {
  width: 85px;
  height: 95px;
  position: absolute;
  bottom: 30px;
  right: 140px;
}
}
/* choose-use-section */
.why-header{
  font-weight: 700;
  font-family: 'Montserrat';
  color: rgba(1, 13, 38, 1);
}
.why-section .sub-header {
    font-weight: 600;
    font-family: 'Mulish';
    color: rgba(16, 16, 16, 1);
}
.why-section .txt {
    color: rgba(114, 114, 114, 1);
    font-weight: 400;
    font-family: 'Mulish';
    font-size: 18px;
}
.star-img{
  width:22px;
  height: 22px;
}
/* competencies-section */
.competencies-section {
    background: rgba(13, 13, 13, 1);
}
.competencies-section .heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}
.competencies-section .sub-heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: rgba(133, 133, 133, 1);

}
.competencies-section .card {
    width: 350px;
    height: 250px;
    border-radius: 20px;
    background: rgba(16, 16, 16, 1);
}
.competencies-section .card img {
    max-width: 46px !important;
}
.competencies-section .card-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
}
.competencies-section .card-text {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    color: rgba(133, 133, 133, 1);
}
.competencies-section .card-header-img {
    width: 46px;
    height: 46px;
    border-radius: 10px;
    background: rgba(115, 227, 134, 1);
    padding: 5px;
}
.competencies-section .card:hover .card-header-img {
    width: 46px;
    height: 46px;
    border-radius: 10px;
    background: rgba(16, 16, 16, 1);
    padding: 5px;
} 
.competencies-section .card:hover .card-header-img .comp {
    filter:  brightness(0) invert(1);
}
.competencies-section .card:hover {
    background: rgba(115, 227, 134, 1);
    filter: drop-shadow(30px 30px 80px rgba(115, 227, 134, 1));
}
@media (min-width: 1200px) {
    .competencies-section .shadow-card {
        background: rgba(115, 227, 134, 1);
        filter: drop-shadow(30px 30px 80px rgba(115, 227, 134, 1));
    }
    .competencies-section .shadow-card .card-title {
        color: rgba(16, 16, 16, 1);
    }
    .competencies-section .shadow-card .card-text {
        color: rgba(38, 88, 47, 1) !important;
    }
    .competencies-section .shadow-card .card-header-img {
        background: rgba(16, 16, 16, 1) !important;
    }
    .competencies-section .shadow-card .card-header-img .comp {
        filter:  brightness(0) invert(1);
    }
}
.competencies-section .card:hover .card-title {
    color: rgba(16, 16, 16, 1);
}
.competencies-section .card:hover .card-text {
    color: rgba(38, 88, 47, 1) !important;
}
/* client-section */
.client-section {
  background-image: url("~@/assets/client-bg.png");
  background-repeat: no-repeat;
  background-position: left !important;
}

.client-section .heading {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
  font-weight: 700;
}

.client-section .sub-txt {
  color: rgba(114, 114, 114, 1);
  font-family: "Mulish";
  font-weight: 400;
}

.client-section .txt {
  color: rgba(1, 13, 38, 1);
  font-family: "Mulish";
  font-weight: 400;
}

.client-section .name {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
  font-weight: 600;
}

.client-section .double {
  width: 25px;
}

.client-section .clientImg {
  width: 60px;
  height: 60px;
}

.client-section .slider {
  height: 750px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  /* place-items: center; */
}

.client-section .slide-track {
  /* display: flex; */
  height: 300px;
  width: calc(100%);
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(-250px));
  }
}

.client-section .slide-track2 {
  /* display: flex; */
  height: 300px;
  width: calc(100%);
  margin-top: -300px;
  animation: scroll2 10s linear infinite;
}

@keyframes scroll2 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(250px));
  }
}

.client-section .slide {
  height: auto;
  width: 100%;
  display: flex;
  /* align-items: center; */
  padding: 13px;
  perspective: 100px;
  /* margin-bottom: 50px; */
}

.client-section img {
  width: 100%;
  transition: transform 1s;
}

.client-section img:hover {
  transform: translateZ(20px);
}

.client-section .slider::before,
.client-section .slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rbga(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.client-section .slider::before {
  left: 0;
  top: 0;
}

.client-section .slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.client-section #mainGate {
  overflow: scroll;
}

.client-section .card {
  border-radius: 20px !important;
  background: rgb(239, 242, 250);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border: none;
}

.client-section .card .cards-footer {
  background: #fff;
  border-radius: 0px 0px 20px 20px !important;
}

.client-section #mainGate::-webkit-scrollbar {
  height: 0;
}

@media screen and (max-width: 768px) {
  .client-section #mainGate {
    width: 100%;
    height: auto;
  }

  .client-section .slide-track {
    display: flex;
    justify-content: space-evenly;
    height: 300px;
    width: 260%;
    animation: scroll 0s linear infinite;
  }

  .client-section .slide {
    width: 100%;
    /* margin-bottom: 50px; */
    margin-right: 10px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px));
    }
  }

  .client-section .slide-track {
    /* display: flex; */
    animation: scroll3 10s linear infinite;
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px));
    }
  }
}
/* solutions-section */
.solutions-section{
    background-image: url("~@/assets/Subtract.png");
    background-size: contain;
    background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
    .solutions-section {
        background-position: center center;
    }
}
.solutions-section .heading {
    color: rgba(115, 227, 134, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.solutions-section .sub-heading {
    color: rgba(16, 16, 16, 1);
    font-family: 'Mulish';
    font-weight: 600;
}
.solutions-section .card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 100px;
    margin-bottom: 14px;
    background: transparent;
    border: none;
    box-shadow: none !important;
}
.solutions-section .sol-img1 {
    width: 140px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img2 {
    width: 200px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img3 {
    width: 300px;
    height: 140px;
    /* object-fit: contain; */
}
.solutions-section .sol-img4 {
    width: 400px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img5 {
    width: 160px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img6 {
    width: 160px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img7 {
    width: 170px;
    height: 100px;
    /* object-fit: contain; */
}
.solutions-section .sol-img8 {
    width: 200px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img9 {
    width: 210px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img10 {
    width: 180px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img11 {
    width: 190px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img12 {
    width: 250px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img13 {
    width: 200px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img14 {
    width: 220px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img15 {
    width: 230px;
    height: 100px;
    object-fit: contain;
}
.solutions-section .sol-img16 {
    width: 150px;
    height: 100px;
}
/* faq-section */
.faq-design {
    background-image: url("~@/assets/faq-bg.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.faq-design .question {
  font-weight: 700;
  cursor: pointer;
}
.faq-design .heading {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.faq-design .faq-box:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.far.fa-plus-square,
.far.fa-minus-square {
  color: #2785ff;
}
.faq-arrow {
    max-width: 200px;
}
.faq-design .question {
    color: rgba(16, 16, 16, 1) !important;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: larger;
}
.faq-design .collapse {
    color: rgba(16, 16, 16, 1) !important;
    font-family: 'Montserrat';
    font-weight: 400;
}
.faq-design .faq-text {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: 'Open Sans';
}
@media (max-width: 767.98px) {
    .faq-box{
        padding: 0px 16px;
    }
}
/* contact-section */
.contact-section {
    background-image: url("~@/assets/contact-bg.png");
    background-repeat: no-repeat;
    background-position: right !important;
    color: #000;
    font-family: "Exo", sans-serif;
}
.contact-section .contact-links {
  font-weight: 600;
}
.contact-section .contact-img {
    max-width: 300px;
}
.contact-section .form-cover {
    width: 86%;
}
.contact-section .form-control {
  font-size: 15px !important;
  color: #858585;
  font-family: Mulish;
  border: 1.5px solid #d1d3db80;
}
.contact-section .form-control:focus {
  box-shadow: none;
}
.contact-section .submitBtn {
    background: #2289fe;
    color: rgba(255, 255, 255, 1);
    border: none;
    font-family: 'Montserrat';
    font-weight: 600;
}
.contact-section .heading {
    color: rgba(16, 16, 16, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.contact-section label {
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(18, 18, 18, 1);
}
.contact-section input {
    font-family: 'Mulish';
    font-weight: 400;
    color: rgba(133, 133, 133, 1);
}
/* footer-section */
.footer .links-header {
    color: rgba(39, 133, 255, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.footer .links-item {
    color: rgba(1, 13, 38, 1);
    font-family: 'Mulish';
    font-weight: 500;
}
.footer .copyright {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
}
.footer .copyright-conditions {
    color: rgba(0, 52, 109, 1);
    font-family: 'Montserrat';
}
@media (max-width: 767.98px) { 
    .contact-section {
        background-position: center !important;
    }
    .hero {
        background-size: cover !important;
    }
    .client-section .slider {
        height: 410px;
    }
}
.desc-img {
    width: 140px;
}
</style>