<template>
  <div class="main">


    <div
      class="modal fade"
      id="email-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div>
              <section
                class="clock p-1 d-flex align-items-center justify-content-center"
              >
                <div class="p-4" style="width: 25rem; border-radius: 20px">
                  <div
                    class="card-body d-flex flex-column align-items-center justify-content-center"
                  >
                    <div class="py-3 px-2">
                      <img
                        src="@/assets/Group 136.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <h3 class="verify-btn py-2 lh-sm mb-0 text-center">
                      Grateful for Your Contact!
                    </h3>
                    <div class="pb-2 color">
                      <p class="text-center">
                        Thank You for Reaching Out to Us.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- hero-section -->
    <section class="hero pb-md-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 d-flex align-items-center justify-content-center">
                    <div>
                        <img src="@/assets/career-hero-img.png" alt="" class="img-fluid hero-img"/>
                    </div>
                </div>
                <div class="col-md-6 pt-md-5">
                    <div class="pt-md-5 d-flex flex-column">
                        <div class="float-start pt-5">
                        <img src="@/assets/smallStar.svg" alt="" class="star-top img-fluid">
                        </div>
                        <h1 class="pt-3 heading text-md-start text-center">Elevate Your <span style="color: rgba(115, 227, 134, 1) !important; font-weight: 700;">Possibilities</span><br> with <span style="color: rgba(39, 133, 255, 1) !important; font-weight: 700;">Innovative</span> Software<br> Solutions</h1>
                            <div class="pt-2">
                            <div class="newsletter js-rollover d-flex flex-column align-items-md-start justify-content-md-start align-items-center justify-content-center">
                                <form class="newsletter-form" name="submit-to-google-sheet" id="newsform">
                                    <input type="email" name="Emails" placeholder="Enter your email" autocomplete="off" required>
                                    <button type="submit" class="button">Let's Talk</button>
                                </form>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="float-end">
                                                <img src="@/assets/bigStar.svg" alt="" class="star-bottom img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-md-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of hero-section -->

    <!--join-us section-->
    <section class="join-us py-5">
        <div class="container py-md-5">
            <div class="row py-md-5">
                <div class="col-md-6 d-flex align-items-center justify-content-center px-md-5 mb-5">
                    <h1 class="heading display-2" style="font-weight: 700;">Why<br> Join Us</h1>
                </div>
                <div class="col-md-6 d-flex align-items-center justify-content-center">
                    <div class="d-flex">
                        <div class="mchooseCol2">
                            <div class="d-flex mb-4">
                                <div>
                                    <img class="star-img" src="@/assets/career1.png" alt=""/>
                                </div>
                                <div class="ps-3">
                                    <h5 class="sub-header">A Growth-Led Environment</h5>
                                    <span class="txt">Uncover the world of huge expertise, growth, and opportunities to empower yourself by acquiring new skills and gaining experience for a successful and fulfilling  career</span>
                                </div>
                            </div>
                            <div class="d-flex mb-4">
                                <div>
                                    <img class="star-img" src="@/assets/career2.png" alt="" />
                                </div>
                                <div class="ps-3">
                                    <h5 class="sub-header">Join a team that celebrates each other</h5>
                                    <span class="txt">Exchanging thoughtful feedback. We push ourselves to do things differently, learn from others and follow our curiosity.</span>
                                </div>
                            </div>
                            <div class="d-flex mb-4">
                                <div>
                                    <img class="star-img" src="@/assets/career3.png" alt="" />
                                </div>
                                <div class="ps-3">
                                    <h5 class="sub-header">Building the future of e-commerce</h5>
                                    <span class="txt">We offer not only a safe and healthy workplace but also extensive professional development opportunities through <b>Digital Spike Academy.</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of join-us section-->

    <!-- benefits-section -->
    <section class="benefits-section mb-5"> 
    <div class="container">
    <div class="row">
      <div class="col-md-4 d-flex flex-column align-items-md-left justify-content-md-center align-items-left justify-content-center">
        <h1 class="py-3 text-md-start text-center heading">Benefits</h1>
        <p class="text-md-start text-center sub-heading">
          “Happy employees keep happy clients” 
          It's important for us to hire fun, energetic candidates who love solving problems and delighting clients, that's what makes Digital Spike Technologies a great company.
        </p>
      </div>
      <div
        class="col-md-4 d-flex flex-column  align-items-center justify-content-center"
      >
        <div class="benefits-card mb-4 mb-md-0">
          <div class="card-body p-4 text-left">
            <div>
              <img
                class="double"
                src="@/assets/HEPtraining.png"
                alt=""
              />
            </div>
            <div class="text-left">
              <h5 class="pt-2">
                Higher Education <br />
                Program and Training
              </h5>
            </div>
            <p class="pt-1 txt">
              Often design training programs to enhance specific skills relevant
              to their industry or the roles their employees perform.
            </p>
          </div>
        </div>
        <div class="benefits-card mt-md-5 mb-md-0">
          <div class="card-body p-4 text-left">
            <div>
              <img
                class="double"
                src="@/assets/offficePerk.png"
                alt=""
              />
            </div>
            <div class="text-left">
              <h5 class="pt-2">Office Perks</h5>
            </div>
            <p class="pt-1 txt">
              Implementing programs to acknowledge and reward employee
              achievements, through monetary bonuses, gift cards, or other
              recognition methods.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-md-4 d-flex flex-column align-items-md-start justify-content-md-start align-items-center justify-content-center mt-md-5"
      >
        <div class="benefits-card mb-4 mb-md-0 mt-md-4">
          <div class="card-body p-4 text-left">
            <div>
              <img class="double" src="@/assets/flexiWork.png" alt="" />
            </div>
            <div class="text-left">
              <h5 class="pt-2">Flexi working</h5>
            </div>
            <p class="pt-1 txt">
              Allows employees to align their work hours with their personal
              preferences, family obligations, or other commitments. can work
              from locations outside the traditional office, such as home or
              co-working spaces.
            </p>
          </div>
        </div>
        <div class="benefits-card mt-md-5 mb-md-0">
          <div class="card-body p-4 text-left">
            <div>
              <img class="double" src="@/assets/timeOff.png" alt="" />
            </div>
            <div class="text-left">
              <h5 class="pt-3">Time off</h5>
            </div>
            <p class="pt-1 txt">
                DigitalSpike Technologies values work-life balance, 
                providing employees with flexible and enriching time-off
                 options for rejuvenation and well-being.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </section>
    <!-- end of benefits-section -->

    <!-- cards-section -->
    <section class="growth pt-md-5 pb-md-5">
        <div class="container pb-5">
            <div class="row flex-wrap-reverse">
                <div class="col-md-6">
                    <div class="card mb-3" style="border-radius: 15px">
                        <div class="card-title pt-3" style="display: flex; align-items: center">
                            <img src="../assets/star.png" class="px-3" />
                            <h6 class="pt-1 card-titles">Training & Development</h6>
                        </div>
                        <div class="card-body pt-0">
                            Initiatives contribute significantly to career growth within a
                            company by providing employees with the skills, knowledge, and
                            experiences necessary for advancement.
                        </div>
                    </div>
                    <div class="card mb-3" style="border-radius: 15px; margin-top: -10px; transform: rotate(-4deg); z-index: 1; background:linear-gradient(0deg, #101010, #101010);color: #858585;">
                        <!-- Adjusted margin and added transform and z-index -->
                        <div class="card-title pt-3" style="display: flex; align-items: center">
                            <img src="../assets/star.png" class="px-3" />
                            <h6 class="pt-1 card-titles">Collaboration Platform</h6>
                        </div>
                        <div class="card-body pt-0">
                            Enables teams to work together seamlessly, regardless of physical locations, fostering a collaborative environment.
                        </div>
                    </div>
                    <div class="card mb-3" style="border-radius: 15px">
                        <div class="card-title pt-3" style="display: flex; align-items: center">
                            <img src="../assets/star.png" class="px-3" /> 
                            <h6 class="pt-1 card-titles">Internal Job Hiring</h6>
                        </div>
                        <div class="card-body pt-0">
                            Internal job hiring is a strategic approach to talent management
                            that not only benefits individual employees by providing growth
                            opportunities but also contributes to the overall success and
                            sustainability of the organization.
                        </div>
                    </div>
                    <div class="card mb-3" style="border-radius: 15px">
                        <div class="card-title pt-3" style="display: flex; align-items: center">
                            <img src="../assets/star.png" class="px-3" />
                            <h6 class="pt-1 card-titles">Leadership Program</h6>
                        </div>
                        <div class="card-body pt-0">
                            Leaders are trained to build high-performing teams that
                            collaborate seamlessly to achieve common objectives.
                        </div>
                    </div>
                </div>
                <div class="col-md-6 px-5 my-auto"> <!-- Added my-auto -->
                        <div class="mb-3 d-flex flex-column align-items-md-start justify-content-md-start align-items-center justify-content-center  ">
                            <h1 class="heading pb-2">Career Growth</h1>
                            <p class="sub-heading">We grow by empowering our people :
We have grown substantially over the last decade; the credit goes to our people. And we’re ardent believers that empowering people with right opportunities, environment, and support is the way to move forward. So here’s what you can expect when you work with us.
</p>
                        </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of cards-section -->

    <!-- culture-section -->
    <section class="culture pb-5">
        <div class="container pb-md-5">
            <h1 class="pt-3 heading text-center">A Deep Dive into Our Unique Culture</h1>
            <p class="sub-heading text-center pb-4">Explore the limitless possibilities with Digital Spike Technology – where<br> expertise meets innovation.</p>
            <div class="row pt-md-5">
                <div class="col-md-6 mb-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex pb-3">
                            <div>
                                <div class="ps-3">
                                    <img src="@/assets/career-card1.png" alt="" class="img-fluid card-img">
                                </div>
                            </div>
                            <div>
                                <h6 class="card-title ps-2 pt-2">Strategic Guidance</h6>
                            </div>
                        </div>
                        <ul class="list-items">
                            <li>We foster a culture of positive assumptions regarding the intentions of our clients, colleagues, and other business partners. We believe in granting each other the benefit of the doubt and consistently reaffirming our trust in their actions and motivations.</li>
                            <li>We aim to cultivate lasting relationships and foster a culture of collaboration.</li>
                            <li>We guide, educate, and encourage one another to exemplify exemplary behavior, while also maintaining mutual accountability.</li>
                        </ul>
                      </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex pb-3">
                            <div>
                                <div class="ps-3">
                                    <img src="@/assets/career-card2.png" alt="" class="img-fluid card-img">
                                </div>
                            </div>
                            <div>
                                <h6 class="card-title ps-2 pt-1">Cultivate and Develop Outstanding Professionals</h6>
                            </div>
                        </div>
                        <ul class="list-items">
                            <li>We foster a culture of ongoing learning</li>
                            <li>We seek individuals based on their enthusiasm for growth and learning, valuing their potential for development rather than solely their existing skills</li>
                            <li>We recruit individuals for their commitment to personal and professional development, placing emphasis on their eagerness to learn and grow, rather than solely relying on their current skill set.</li>
                            <li>We stand by one another in unlocking our full potential and evolving into the most accomplished versions of ourselves.</li>
                        </ul>
                      </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex pb-3">
                            <div>
                                <div class="ps-3">
                                    <img src="@/assets/career-card3.png" alt="" class="img-fluid card-img">
                                </div>
                            </div>
                            <div>
                                <h6 class="card-title ps-2 pt-2">Think Big, Act Swiftly.</h6>
                            </div>
                        </div>
                        <ul class="list-items">
                            <li>We question the status quo and challenge conventional wisdom to foster innovation and drive positive change</li>
                            <li>We conceive compelling and awe-inspiring ideas, swiftly bringing them to fruition. Embracing accountability, we own our failures, learn from them, and adapt quickly.</li>
                            <li>We embody the roles of scientists, entrepreneurs, and architects shaping a new human-centric world where AI is seamlessly integrated. Leveraging the transformative capabilities of AI, we drive change within ourselves, for our clients, and across the globe.</li>
                        </ul>
                      </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex pb-3">
                            <div>
                                <div class="ps-3">
                                    <img src="@/assets/career-card4.png" alt="" class="img-fluid card-img">
                                </div>
                            </div>
                            <div>
                                <h6 class="card-title ps-2 pt-2">Unwavering Trust and Take Full Accountability</h6>
                            </div>
                        </div>
                        <ul class="list-items">
                            <li>We approach our interactions with clients, colleagues, and other business partners with a presumption of positive intent. By giving each other the benefit of the doubt and reaffirming trust, we foster a collaborative and supportive environment.</li>
                            <li>We aim to cultivate lasting relationships and foster a culture of collaboration.</li>
                            <li>We guide, educate, and encourage each other to exhibit exemplary behavior, holding ourselves mutually accountable.</li>
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of culture-section -->

    <!-- apply-section -->
    <section class="apply-section py-5 d-none d-lg-block">
        <div class="container">
            <div class="pb-5">
                <h1 class="text-center heading">How To Apply</h1>
                <p class="text-center sub-heading"> It’s easier than you think. Just apply via a specific job offer link.</p>
            </div>
            <div class="d-flex flex-column align-items-md-start justify-content-md-start align-items-center justify-content-center">
                <div class="card-1">
                    <div class="card">
                      <div class="card-body">
                        <h6 class="card-title">Applying</h6>
                        <p class="card-text">
                            Discover job openings, choose your ideal role, and effortlessly apply with a cover letter, CV, and required documents through our user-friendly platform.
                        </p>
                      </div>
                    </div>
                </div>
                <div class="card-new" style="padding-left: 50px;">
                    <div class="d-flex">
                        <div class="mt-2">
                            <img src="@/assets/cards-arrow.png" alt="" class="img-fluid arrow">
                        </div>
                        <div class="card mt-4">
                            <div class="card-body">
                                <h6 class="card-title">Application Review</h6>
                                <p class="card-text">The online application consideration, screening interview whether the candidate is suitable for the role  if it should proceed to the next stage.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-new" style="padding-left: 180px;">
                    <div class="d-flex">
                        <div class="mt-2">
                            <img src="@/assets/cards-arrow.png" alt="" class="img-fluid arrow">
                        </div>
                        <div class="card mt-4">
                            <div class="card-body">
                                <h6 class="card-title">Pre-Interview</h6>
                                <p class="card-text">Engage in personalized one-on-one interviews with our hiring manager, discussing your qualifications, skills, work history, and why you're an ideal fit for our firm.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-new" style="padding-left: 310px;">
                    <div class="d-flex">
                        <div class="mt-2">
                            <img src="@/assets/cards-arrow.png" alt="" class="img-fluid arrow">
                        </div>
                        <div class="card mt-4">
                            <div class="card-body">
                                <h6 class="card-title">Interview</h6>
                                <p class="card-text">We’ll bring you on-site to deeper dive into your experience and technical competence. This round is based on your resume, and branch of specialization.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-new" style="padding-left: 430px;">
                    <div class="d-flex">
                        <div class="mt-2">
                            <img src="@/assets/cards-arrow.png" alt="" class="img-fluid arrow">
                        </div>
                        <div class="card mt-4">
                            <div class="card-body">
                                <h6 class="card-title">Decision</h6>
                                <p class="card-text">Whether extending an offer or offering interview advice, we meticulously review applications to select candidates poised for success in their roles or programs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="apply-section py-5 d-lg-none">
        <div class="container">
            <div class="pb-5">
                <h1 class="text-center heading">How To Apply</h1>
                <p class="text-center sub-heading">Expand your career by growing and polishing your skills in a professional environment with Digital Spike Technologies experts.
Below is the gate of opportunity for you. If you think, you have the zeal to fill up this space, then email us your resume or updated CV to 
</p>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
                <div>
                    <div class="card">
                      <div class="card-body">
                        <h6 class="card-title">Applying</h6>
                        <p class="card-text">
                            Discover job openings, choose your ideal role, and effortlessly apply with a cover letter, CV, and required documents through our user-friendly platform.
                        </p>
                      </div>
                    </div>
                </div>
                <div>
                    <img src="@/assets/cards-arrow2.png" alt="" class="img-fluid arrow2">
                </div>
                <div>   
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Application Review</h6>
                            <p class="card-text">The online application consideration, screening interview whether the candidate is suitable for the role  if it should proceed to the next stage.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <img src="@/assets/cards-arrow2.png" alt="" class="img-fluid arrow2">
                </div>
                <div>   
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Pre-Interview</h6>
                            <p class="card-text">Engage in personalized one-on-one interviews with our hiring manager, discussing your qualifications, skills, work history, and why you're an ideal fit for our firm.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <img src="@/assets/cards-arrow2.png" alt="" class="img-fluid arrow2">
                </div>
                <div>   
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Interview</h6>
                            <p class="card-text">We’ll bring you on-site to deeper dive into your experience and technical competence. This round is based on your resume, and branch of specialization.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <img src="@/assets/cards-arrow2.png" alt="" class="img-fluid arrow2">
                </div>
                <div>   
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Decision</h6>
                            <p class="card-text">Whether extending an offer or offering interview advice, we meticulously review applications to select candidates poised for success in their roles or programs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="container py-5">
        <div class="d-flex flex-column align-items-center justify-content-center pt-md-5">
            <h1 class="apply-now text-center">Apply Now</h1>
            <p class="text-center apply-now-text">Expand your career by growing and polishing your skills in a professional environment with Digital Spike Technologies experts.<br>
Below is the gate of opportunity for you. If you think, you have the zeal to fill up this space, then email us your resume or updated CV to 
</p>
            <div class="d-flex pt-4 pb-2">
                <div class="pe-4">
                   <a href="https://www.linkedin.com/company/digital-spike-technologies/"> <img  src="@/assets/linkedin 1.png" alt="" class="img-fluid apply-img"></a>
                </div>
                <!-- <div class="pe-4">
                       <a href=""><img style="width:60px;height:60px;box-shadow:1px 1px 4px gray;border-radius:8px" src="https://static.businessworld.in/article/article_extra_large_image/1631247583_eYK9Zf_Apna_Logo_1_.png" alt="" class="img-fluid apply-img"></a>
                </div> -->
                <div class="pe-4">
                    <a href="https://in.indeed.com/cmp/Digital-Spike-Technologies/about"><img  src="@/assets/linkedin 4.png" alt="" class="img-fluid apply-img"></a>
                </div>
            </div>
        </div>
    </div>
    <!-- end of apply-section -->

</div>
</template>

<script>

import Swal from "sweetalert2";

export default {
    name: 'CareerComponent',
    mounted() {

        const scriptURL =
      "https://script.google.com/macros/s/AKfycbzYiEqkkc1Nd-DlDPKpUaGTpYklzUSm16NtiH_FSBYsCwYKyWozMPJJRQZArp0XEf9atA/exec";
    const form = document.forms["submit-to-google-sheet"];

    form.addEventListener("submit", (e) => {
      e.preventDefault();
      fetch(scriptURL, { method: "POST", body: new FormData(form) })
        .then(
          (response) => console.log("Success!", response),
          $("#newsform")[0].reset(),
          $("#email-modal").modal("show"),
          $(".modal-backdrop").hide(),

          setTimeout(function () {
            $("#email-modal").modal("hide");
          }, 2000)
        )
        .catch((error) => console.error("Error!", error.message));
    });
    }
}
</script>

<style scoped>
.main {
    overflow : hidden;
}
/* navbar-section */
.logo{
  width: 160px;
  height: 50px;
}
nav .nav-link {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
nav .active {
    color: rgba(39, 133, 255, 1) !important;
}
nav .download {
    background: rgba(13, 31, 15, 1);
}
nav .btn-txt {
    background: linear-gradient(
    90deg,
    rgba(51, 146, 238, 1) 2%,
    rgba(115, 227, 133, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
nav .btn-txt:hover {
    background: linear-gradient(
    90deg,
    rgba(115, 227, 133, 1) 2%,
    rgba(51, 146, 238, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
@media (max-width: 767.98px) {
    .d{
        padding-top: 14px;
    }
}
/* sub-navbar-section */
.subnav-section{
  margin: 0px 60px;
}
.subnav-class a{
    color: rgba(1, 13, 38, 1);
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
.subnav-class header .tabs{
    display: flex;
    overflow-y: auto;
}

.subnav-class header .tabs a img{
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.subnav-class header .tabs a{
    display: block;
    padding: 0px 13px 13px 30px;
    font-weight: 900;
    outline: none;
    white-space: nowrap;
    text-decoration: none;
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
    cursor: pointer;
}

.subnav-class .tabs::-webkit-scrollbar {
    height: 0;
}

.subnav-class header .tabs a.router-link-active{
   
    border-bottom: 2px solid #73E386;
}
@media screen and (max-width:992px) {
    .subnav-section{
  margin: 0px 0px;
}
}
/* hero-section */

.hero {
    background-image: url("~@/assets/hero.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.hero .heading {
   color: rgba(1, 13, 38, 1);
   font-family: 'Montserrat';
}

.star-top {
    max-width: 80px;
}

.newsletter {
    position: relative;
    /* display: flex;
    align-items: start;
    justify-content: start; */
}
.newsletter .newsletter-form {
    position: relative;
    max-width: 355px;
    margin-top: 23px;
    z-index: 999;
}
.newsletter .newsletter-form input {
    height: 55px;
    width: 100%;
    padding: 20px 135px 17px 20px;
    border: none;
    border-radius: 10px;
    outline: none;
    background-color: #ffffff;
    color: rgba(133, 133, 133, 1);
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.newsletter .newsletter-form button {
    width: 120px;
    height: 38px;
    border-radius: 5px;
    background-color: #000;
    font-size: .875rem;
    line-height: 1;
    position: absolute;
    right: 9px;
    bottom: 9px;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0px;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.since-text {
    color: rgba(16, 16, 16, 1);
    font-family: 'Mulish';
    font-weight: 600;
    letter-spacing: 1.2px;
}
.smallStar{
  width: 60px;
  height: 70px;
  position: absolute;
  top: 210px;
  left: 30px;
   z-index: -1;
}
.bigStar {
  width: 85px;
  height: 95px;
  position: absolute;
  bottom: 240px;
  right: 250px;
  z-index: -1;
}
.box-card {
    width: 350px;
    height: 250px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    border-radius: 10px;
    border: none;
}
.since-header {
    color: #73e386;
    font-family: 'Montserrat';
    font-weight: 700;
}
.project-header {
    color: #2785ff;
    font-family: 'Montserrat';
    font-weight: 700;
}
.employees-header {
    color: #ee7e1b;
    font-family: 'Montserrat';
    font-weight: 700;
}
@media (max-width: 768px) {
.smallStar{
  width: 60px;
  height: 70px;
  position: absolute;
  top: 20px;
  left: 110px;
}
.bigStar {
  width: 85px;
  height: 95px;
  position: absolute;
  bottom: 30px;
  right: 140px;
}
}
/* join-us-section */
.join-us {
    background-image: url("~@/assets/career-b-bg.png");
    background-repeat: no-repeat;
    background-position: left top !important;
}
.join-us .star-img {
    max-width: 60px;
}
.join-us .heading {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.join-us .sub-header {
    color: rgba(16, 16, 16, 1);
    font-family: 'Mulish';
    font-weight: 600;
}
.join-us .txt {
    color: rgba(114, 114, 114, 1);
    font-family: 'Mulish';
    font-weight: 400;
}
/* culture-section */
.culture .card {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    border: none;
    border-radius: 10px;
}
.culture .card-img {
    max-width: 36px;
}
.culture .card-title {
    color: rgba(16, 16, 16, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.culture .list-items{
    color: rgba(133, 133, 133, 1);
    font-family: 'Mulish';
    font-weight: 400;
}
.culture {
    background-image: url("~@/assets/career-g-bg.png");
    background-repeat: no-repeat;
    background-position: right top !important;
}
@media (min-width: 1400px) {
    .culture .card {
        height: 300px;
    }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
    .culture .card {
        height: 310px;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .culture .card {
        height: 368px;
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .culture .card {
        height: 500px;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .culture .card {
        height: 360px;
    }
}
.culture .heading {
    font-family: 'Montserrat';
    font-weight: 700;
    color: rgba(16, 16, 16, 1);
}
.culture .sub-heading {
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(133, 133, 133, 1);
}
/* benefits-section */
.benefits-section{
  padding: 30px;
  background:url("@/assets/benefitBg.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.benefits-section .heading {
    font-family: 'Montserrat';
    font-weight: 700;
    color: rgba(16, 16, 16, 1);
}
.benefits-section .sub-heading {
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 19px;
    color: rgba(133, 133, 133, 1) !important;
}
.benefits-section h5 {
    color: rgba(16, 16, 16, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.benefits-section .txt {
    color: rgba(133, 133, 133, 1);
    font-family: 'Mulish';
    font-weight: 400;
}
.benefits-card{
   width: 350px;
   height: 310px;
   border-radius: 16px;
   background: white;
   margin-bottom: 20px;
   overflow: hidden;
}

.double{
  width: 80px;
  height: 80px;
}
@media only screen and (max-width: 767px) {
  .benefits-card {
    overflow: hidden;
  }
  .benefits-section .sub-heading {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1199px) {
  .benefits-card {
    width: auto; /* Adjust the width as needed */
    height: auto;
  }
}
/* cards-section */
.growth .card {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}
.growth .card-titles {
    font-family: 'Montserrat';
    font-weight: 600;
    color: rgba(16, 16, 16, 1);
}
.growth .card-body {
    color: rgba(133, 133, 133, 1);
    font-family: 'Mulish';
    font-weight: 400;
}
.growth .heading {
    color: rgba(16, 16, 16, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.growth .sub-heading {
    color: rgba(133, 133, 133, 1);
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 18px;
}
@media only screen and (max-width: 767px) {
  .benefits-card {
    overflow: hidden;
  }
  .growth .sub-heading {
    font-size: 16px;
  }
}
/* apply-section */
.apply-section {
    background: rgba(13, 13, 13, 1);
}
.apply-section .heading {
    color: rgba(255, 255, 255, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.apply-section .sub-heading {
    color: rgba(208, 208, 208, 1);
    font-family: 'Montserrat';
    font-weight: 400;
}
.apply-section .card {
    width: 350px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    border-radius: 10px;
}
.apply-section .card:hover {
    background: rgba(115, 227, 134, 1);
}
.apply-section .card .card-title {
    color: rgba(13, 13, 13, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.apply-section .card .card-text {
    color: rgba(133, 133, 133, 1);
    font-family: 'Mulish';
    font-weight: 400;
}
.apply-section .arrow {
    width: 80px;
}
.apply-section .arrow2 {
    padding: 10px 0px;
}
.apply-now {
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(16, 16, 16, 1);
}
.apply-now-text {
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(133, 133, 133, 1);
}
/* contact-section */
.contact-section {
    background-image: url("~@/assets/contact-bg.png");
    background-repeat: no-repeat;
    background-position: right !important;
    color: #000;
    font-family: "Exo", sans-serif;
}
.contact-section .contact-links {
  font-weight: 600;
}
.contact-section .contact-img {
    max-width: 300px;
}
.contact-section .form-cover {
    width: 86%;
}
.contact-section .form-control {
  font-size: 15px !important;
  color: #858585;
  font-family: Mulish;
  border: 1.5px solid #d1d3db80;
}
.contact-section .form-control:focus {
  box-shadow: none;
}
.contact-section .submitBtn {
    background: #2289fe;
    color: rgba(255, 255, 255, 1);
    border: none;
    font-family: 'Montserrat';
    font-weight: 600;
}
.contact-section .heading {
    color: rgba(16, 16, 16, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.contact-section label {
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(18, 18, 18, 1);
}
.contact-section input {
    font-family: 'Mulish';
    font-weight: 400;
    color: rgba(133, 133, 133, 1);
}
/* footer-section */
.footer .links-header {
    color: rgba(39, 133, 255, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.footer .links-item {
    color: rgba(1, 13, 38, 1);
    font-family: 'Mulish';
    font-weight: 500;
}
.footer .copyright {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
}
.footer .copyright-conditions {
    color: rgba(0, 52, 109, 1);
    font-family: 'Montserrat';
}
@media (max-width: 767.98px) { 
    .contact-section {
        background-position: center !important;
    }
}
</style>