<template>
  <div class="main">
    <!-- hero-section -->
    <section class="hero pt-md-5">
        <div class="container">
            <div class="row flex-wrap-reverse">
                <div class="col-md-6">
                    <div class="pt-md-5 d-flex flex-column">
                        <div class="float-start pt-md-5">
                        <img src="@/assets/smallStar.svg" alt="" class="star-top img-fluid">
                        </div>
                        <h1 class="pt-3 pb-2 heading text-md-start text-center">Elevate Your<span style="color: rgba(19, 89, 187, 1) !important; font-weight: 700;"> Presence<br> </span>with Expert Digital<br> Solutions.</h1>
                        <div class="pt-2">
                            <div class="d-flex align-items-md-start justify-content-md-start align-items-center justify-content-center">
                              <div class="download">
                <button type="button" class="btn btn-download btn-txt">Build Your Presence Now</button>
              </div>
              </div>
                            <div class="container">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="float-end">
                                                <img src="@/assets/bigStar.svg" alt="" class="star-bottom img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-md-3"></div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center justify-content-center pt-md-5">
                    <div>
                        <img src="@/assets/uiux-logo.png" alt="" class="img-fluid hero-img"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of hero-section -->

    <!-- process-section -->
    <section class="our-process py-5 d-none d-md-block">
      <div class="container py-md-5 px-5">
        <div class="pb-5">
          <h1 class="text-center heading">Our Process</h1>
          <p class="sub-heading text-center">Designing and developing a website & Mobile App comprises various stages.</p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img src="@/assets/process1.png" alt="" class="img-fluid process-img1">
          </div>
        </div>
      </div>
    </section>
    <section class="our-process py-5 d-md-none">
      <div class="container px-5 py-md-5">
        <div class="pb-5">
          <h1 class="text-center heading">Our Process</h1>
          <p class="sub-heading text-center">Designing and developing a website & Mobile App comprises various stages.</p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img src="@/assets/process2.png" alt="" class="img-fluid process-img2">
          </div>
        </div>
      </div>
    </section>
    <!-- end of process-section -->
    
     <!-- service-section-2 -->
    <section class="service-section-2 pb-md-5 pb-5">
      <div class="container px-5">
        <h1 class="text-center pt-5 service-header">Why DST for Your UI/UX Magic?</h1>
        <p class="text-center pb-5">Ready to dazzle the digital world? Let’s turn your brand into an experience that wows! </p>
       
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Immersive Designs, Intuitive Experiences</h1>
              <p class="sub-heading text-md-start text-center">We sculpt digital magic, turning visions into seamless adventures. Every click feels like second nature.</p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u1.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-2 -->
    
    <!-- service-section-1 -->
    <section class="service-section-1 pb-5">
      <div class="container px-5">
         <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u2.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex flex-column align-items-center justify-content-center mb-3">
            <div>
              <h1 class="pb-2 service-heading display-5 text-md-start text-center">Human-Centric Wonders</h1>
              <p class="text-md-start text-center sub-heading">Beyond pretty pixels, we craft experiences that resonate. Your users won't just see – they'll connect, engage, and fall in love.</p>
              
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-1 -->

    <!-- service-section-2 -->
    <section class="service-section-2 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Innovation as Standard</h1>
              <p class="sub-heading text-md-start text-center">We don't follow trends; we set them. Stay on the cutting edge with designs that redefine what's possible in the digital realm.</p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u3.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-2 -->

    <!-- service-section-3 -->
    <section class="service-section-3 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row ">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u4.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="pt-md-5">
              <h1 class="pt-md-5 pb-2 service-heading display-5 text-md-start text-center">Partners in Creation</h1>
              <p class="text-md-start text-center sub-heading">Your vision guides us. We're not just designers; we're collaborators on a mission to bring your brand to life.</p>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-3 -->

    <!-- service-section-4 -->
    <section class="service-section-4 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
           <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Pixel Perfection, Every Time</h1>
              <p class="sub-heading text-md-start text-center">We're obsessed with the details – because every pixel matters. Your design isn't just beautiful; it's flawless in its precision.</p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u5.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
         
        </div>
      </div>
    </section>
    <!-- end of service-section-4 -->

    <!-- service-section-5 -->
    <section class="service-section-5 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row ">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u6.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Swift to Launch</h1>
              <p class="sub-heading text-md-start text-center">Time is money, and we're not here to waste either. From concept to reality, we fast-track your project without compromising quality.</p>
              
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-5 -->

    <!-- service-section-6 -->
    <section class="service-section-6 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
            <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Mobile Mastery </h1>
              <p class="sub-heading text-md-start text-center">In a mobile-first world, your interface shines on every screen. Responsive design is not a feature; it's our mantra.</p>         
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u7.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <!-- end of service-section-6 -->

    <!-- service-section-7 -->
    <section class="service-section-7 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row ">
            <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u8.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Data-Driven Brilliance</h1>
              <p class="sub-heading text-md-start text-center">No guesswork here – our designs are backed by data. Your UI/UX is not just pretty; it's strategically aligned with your business goals.</p>
              
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <!-- end of service-section-7 -->

    <!-- service-section-8 -->
    <section class="service-section-8 py-md-5 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
            <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Post-Launch Perfection</h1>
              <p class="sub-heading text-md-start text-center">Launch day is just the beginning. We're here for the long haul, ensuring your digital presence stays top-notch.</p>         
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/u9.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <!-- end of service-section-8 -->
  </div>
</template>

<script>
export default {
  name:"uiux"
}
</script>

<style scoped>
.main {
  overflow-x: hidden;
}
.hero .download {
    background: rgba(13, 31, 15, 1);
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero .btn-txt {
    background: linear-gradient(
    90deg,
    rgba(51, 146, 238, 1) 2%,
    rgba(115, 227, 133, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
.hero .btn-txt:hover {
    background: linear-gradient(
    90deg,
    rgba(115, 227, 133, 1) 2%,
    rgba(51, 146, 238, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
.hero .heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
}
.process-img2 {
  height: 800px;
}
/* process-section */
.our-process .sub-heading {
  color : rgba(114, 114, 114, 1);
  font-family: 'Montserrat';
  font-weight: 400;
}
.our-process .heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
/* service-section */
.service-header {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
.service-heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
.sub-heading {
  color: rgba(61, 70, 80, 1);
  font-family: 'Mulish';
  font-weight: 400;
}
.service-section-1 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-2 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-3 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-4 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-5 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-6 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-7 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-8 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-9 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
</style>