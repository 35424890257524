<template>
  <div class="main">
    <!-- hero-section -->
    <section class="hero">
      <div class="container">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <div>
              <img src="@/assets/hero-bg-img.png" alt="" class="img-fluid hero-img" />
            </div>
          </div>
          <div class="col-md-6 pt-md-5">
            <div class="pt-md-5 d-flex flex-column">
              <div class="float-start pt-5">
                <img src="@/assets/smallStar.svg" alt="" class="star-top img-fluid" />
              </div>
              <h1 class="pt-3 heading text-md-start text-center">
                Unleash your inner unicorn:
                <span style="
                    color: rgba(115, 227, 134, 1) !important;
                    font-weight: 700;
                  ">Build your dream business with</span>
                Digital Spike Technologies.
              </h1>
              <div class="pt-2">
                <div
                  class="newsletter js-rollover d-flex flex-column align-items-md-start justify-content-md-start align-items-center justify-content-center">
                  <form class="newsletter-form" name="submit-to-google-sheet" id="newsform">
                    <input type="email" name="Emails" placeholder="Enter your email" autocomplete="off" required />
                    <button type="submit" class="button">Let's Talk</button>
                    <div class="modal fade" id="email-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-body">
                            <div>
                              <section class="clock p-1 d-flex align-items-center justify-content-center">
                                <div class="p-4" style="width: 25rem; border-radius: 20px">
                                  <div class="card-body d-flex flex-column align-items-center justify-content-center">
                                    <div class="py-3 px-2">
                                      <img src="@/assets/Group 136.png" alt="" class="img-fluid" />
                                    </div>
                                    <h1 class="verify-btn py-2 lh-sm mb-0 text-center">
                                      Grateful for Your Contact!
                                    </h1>
                                    <div class="pb-2 color">
                                      <p class="text-center">
                                        Thank You for Reaching Out to Us.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="container">
                    <div class="row">
                      <div class="col-md-9">
                        <div class="float-end">
                          <img src="@/assets/bigStar.svg" alt="" class="star-bottom img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container hero-cards pb-md-0 pb-5">
        <div class="row">
          <div
            class="col-md-4 d-flex align-items-md-end justify-content-md-end align-items-center justify-content-center">
            <div class="card mb-3 box-card d-flex flex-column align-items-center justify-content-center">
              <h1 class="since-header">2017</h1>
              <p class="since-text">SINCE</p>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-center justify-content-center">
            <div class="card mb-3 box-card d-flex flex-column align-items-center justify-content-center">
              <h1 class="project-header">50+</h1>
              <p class="since-text">PROJECTS</p>
            </div>
          </div>
          <div
            class="col-md-4 d-flex align-items-md-start justify-content-md-start align-items-center justify-content-center">
            <div class="card mb-3 box-card d-flex flex-column align-items-center justify-content-center">
              <h1 class="employees-header">100+</h1>
              <p class="since-text">EMPLOYEES</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of hero-section -->

    <!-- service-section -->
    <section class="service-section py-5 px-md-0 px-3">
      <div class="container pt-md-5">
        <div class="pt-md-5">
          <h1 class="py-5 text-center heading">Our Services</h1>
          <div class="d-flex flex-wrap gap-5 align-items-center justify-content-center">
            <div>
              <div class="card">
                <div class="card-header pt-1">
                  <img src="@/assets/service1.png" alt="" class="img-fluid service-img" />
                </div>
                <div class="card-body px-4 pt-1">
                  <h6 class="card-title">Digital Marketing</h6>
                  <p class="card-text">
                    Minimize your workload and maximize your success with our
                    comprehensive
                    <b style="color: black">Best digital marketing services,</b>
                    from brand launches to enhancing online visibility,
                    attracting website traffic, generating leads, and optimizing
                    conversions, we've got it all covered. Contact our
                    exceptional agency today to take your business to new
                    heights. With our expertise in strategic planning and
                    branding, we'll ensure your business thrives in the market.
                  </p>
                </div>
                <div class="card-footer pt-0 pb-4">
                  <a href="/digital_marketing">
                    <img src="@/assets/service-btn.png" alt="" class="img-fluid service-btn-img" />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div class="card">
                <div class="card-header pt-1">
                  <img src="@/assets/service2.png" alt="" class="img-fluid service-img" />
                </div>
                <div class="card-body px-4 pt-1">
                  <h6 class="card-title">Website Development</h6>
                  <p class="card-text">
                    We specialize in website development services, crafting
                    digital experiences that captivate and engage. Our expert
                    team leverages cutting-edge technologies to build
                    responsive, user-friendly websites tailored to your unique
                    needs. From sleek corporate sites to dynamic e-commerce
                    platforms, we bring your vision to life. With a focus on
                    functionality, aesthetics, and seamless navigation. Let us
                    create the perfect online presence for you.
                  </p>
                </div>
                <div class="card-footer pt-0 pb-4">
                  <a href="/website_development">
                    <img src="@/assets/service-btn.png" alt="" class="img-fluid service-btn-img" />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div class="card">
                <div class="card-header pt-2">
                  <img src="@/assets/service3.png" alt="" class="img-fluid service-img" />
                </div>
                <div class="card-body px-4 pt-2">
                  <h6 class="card-title">Web Application Development</h6>
                  <p class="card-text">
                    We specialize in creating custom web solutions that are
                    perfectly aligned with your business objectives. Our team of
                    experienced developers deliver cutting-edge applications
                    that are uniquely tailored to meet your specific needs. With
                    a proven track record in executing successful projects on
                    the global stage, we consistently offer high-quality,
                    on-time services. Choose us for expert and innovative web
                    app development to propel your business forward. Our
                    commitment to excellence and innovation ensures that your
                    business stays ahead in this fast-paced digital world.
                  </p>
                </div>
                <div class="card-footer pt-0 pb-4">
                  <a href="/web_application">
                    <img src="@/assets/service-btn.png" alt="" class="img-fluid service-btn-img" />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div class="card">
                <div class="card-header pt-3">
                  <img src="@/assets/service4.png" alt="" class="img-fluid service-img" />
                </div>
                <div class="card-body px-4 pt-3">
                  <h6 class="card-title">Mobile Application Development</h6>
                  <p class="card-text">
                    Mobile application development is a critical aspect in
                    today's digital age where businesses need to keep up with
                    the ever-changing technological landscape, at our company,
                    we specialize in offering mobile app development services,
                    providing tailored solutions that cater to the unique
                    requirements of businesses around the globe. We take pride
                    in our track record of successful projects delivered
                    worldwide, establishing a reputation for high-quality
                    services and punctual delivery. Trust us to turn your app
                    ideas into reality, enhancing your business growth and
                    customer engagement.
                  </p>
                </div>
                <div class="card-footer pt-0 pb-4">
                  <a href="/mobile_application">
                    <img src="@/assets/service-btn.png" alt="" class="img-fluid service-btn-img" />
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div class="card">
                <div class="card-header pt-1">
                  <img src="@/assets/service5.png" alt="" class="img-fluid service-img pb-2" />
                </div>
                <div class="card-body px-4 pt-0">
                  <h6 class="card-title">UI/UX Design</h6>
                  <p class="card-text">
                    Whether you are looking to revamp an existing interface or
                    build a new one from scratch, our team of experienced
                    designers can help you create an engaging and user-friendly
                    design that enhances the overall user experience. This
                    allows us to create designs that not only look great but
                    also provide a seamless experience for your target audience.
                    We believe in creating designs that not only look good but
                    also function seamlessly, providing users with a seamless
                    journey through your digital platform.
                  </p>
                </div>
                <div class="card-footer pt-0 pb-4">
                  <a href="/ui_ux">
                    <img src="@/assets/service-btn.png" alt="" class="img-fluid service-btn-img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of service-section -->

    <!-- choose-us-section -->
    <section class="why-section pt-3 pb-5">
      <div class="container">
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <div class="px-lg-5 px-2">
              <h1 class="why-header mb-4 text-start display-6">
                Why Choose Us
              </h1>
              <div class="d-flex">
                <div class="mchooseCol2">
                  <div class="d-flex mb-4">
                    <div>
                      <img class="star-img" src="@/assets/starList.svg" alt="" />
                    </div>
                    <div class="ps-2">
                      <h5 class="sub-header">Tailored Solutions</h5>
                      <span class="txt">Co-create a focused project brief for your website and
                        app, ensuring seamless design and development alignment
                        with your business vision.</span>
                    </div>
                  </div>
                  <div class="d-flex mb-4">
                    <div>
                      <img class="star-img" src="@/assets/starList.svg" alt="" />
                    </div>
                    <div class="ps-2">
                      <h5 class="sub-header">Consistent Design Brilliance</h5>
                      <span class="txt">Experience clear, engaging design that maintains brand
                        consistency across digital channels, culminating in a
                        flawless user journey.</span>
                    </div>
                  </div>
                  <div class="d-flex mb-4">
                    <div>
                      <img class="star-img" src="@/assets/starList.svg" alt="" />
                    </div>
                    <div class="ps-2">
                      <h5 class="sub-header">Efficient Launch Preparation</h5>
                      <span class="txt">Trust our QA excellence for bug-free delivery, and let
                        our strategists orchestrate an optimal 30-day launch
                        campaign for your new digital presence.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-md-center justify-content-md-center">
            <img src="@/assets/WhyChooseImage.png" alt="" class="img-fluid hero-img" />
          </div>
        </div>
      </div>
    </section>
    <!-- end of choose-us-section -->

    <!-- competencies-section -->
    <section class="competencies-section">
      <div class="container-lg container-fluid py-5">
        <div class="pb-3">
          <h1 class="text-center heading">
            Our Key Strengths and Expertise Content
          </h1>
          <p class="text-center sub-heading">
            Explore the limitless possibilities with Digital Spike Technology –
            where<br />
            expertise meets innovation.
          </p>
        </div>
        <div class="d-flex flex-row flex-wrap gap-4 align-items-center justify-content-center">
          <div class="card">
            <div class="card-body">
              <div class="card-header-img d-flex -align-items-center justify-content-center">
                <img src="@/assets/comp1.svg" alt="" class="img-fluid comp" />
              </div>
              <h6 class="card-title pt-2">Comprehensive Strategy</h6>
              <p class="card-text">
                Develop and execute data-driven, holistic digital marketing
                strategies tailored to each client's unique goals and target
                audience.
              </p>
            </div>
          </div>
          <div class="card card">
            <div class="card-body">
              <div class="card-header-img d-flex -align-items-center justify-content-center">
                <img src="@/assets/comp22.svg" alt="" class="img-fluid comp" />
              </div>
              <h6 class="card-title pt-2">Analytics and Reporting</h6>
              <p class="card-text">
                Utilize advanced analytics tools to track campaign performance,
                extract actionable insights, and provide clients with
                transparent and detailed reports.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-header-img d-flex -align-items-center justify-content-center">
                <img src="@/assets/comp3.svg" alt="" class="img-fluid comp" />
              </div>
              <h6 class="card-title pt-2">Customized Solutions</h6>
              <p class="card-text">
                Develop bespoke websites tailored to client specifications,
                ensuring a unique and user-centric online presence.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-header-img d-flex -align-items-center justify-content-center">
                <img src="@/assets/comp4.svg" alt="" class="img-fluid comp" />
              </div>
              <h6 class="card-title pt-2">Web Security</h6>
              <p class="card-text">
                Implement rigorous security measures to protect websites from
                potential threats, ensuring data integrity and user trust.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-header-img d-flex -align-items-center justify-content-center">
                <img src="@/assets/comp5.svg" alt="" class="img-fluid comp" />
              </div>
              <h6 class="card-title pt-2">Quality Assurance:</h6>
              <p class="card-text">
                Implement rigorous testing processes to ensure the reliability,
                performance, and security of mobile applications.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-header-img d-flex -align-items-center justify-content-center">
                <img src="@/assets/comp6.svg" alt="" class="img-fluid comp" />
              </div>
              <h6 class="card-title pt-2">User-Centric Design</h6>
              <p class="card-text">
                Prioritize user experience (UX) and user interface (UI) design
                to create intuitive, visually appealing, and highly functional
                mobile applications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of competencies-section -->

    <!-- client-section -->
    <section class="client-section pt-5 pb-md-5">
      <div class="container py-md-5">
        <div>
          <h4 class="" style="font-style: popines;">EXCELLENT <span class="text-warning"><svg
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill"
                viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-star-fill" viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-star-fill" viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-star-fill" viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-star-fill" viewBox="0 0 16 16">
                <path
                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg></span> </h4>
        </div>
        <div>
          <h4> 80 reviews on <span><img src="@/assets/blogs/logo-one.svg" class="google-img img-fluid" style="width: 6%;"
                alt=""></span> </h4>

        </div>

        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center">
            <div>


              <div class="text-lg-start text-center">
                <img class="w-25" src="@/assets/bluequotes.svg" alt="" />
              </div>
              <h1 class="text-lg-start text-center py-3 d-none d-lg-block heading">What Our <br>Clients <br>Say</h1>



              <h1 class="text-center d-lg-none heading py-3">What Our Clients Say</h1>
              <p class="text-lg-start text-center sub-txt">Turn on the power of our IT solutions through inspiring client
                testimonials. From enhanced efficiency to remarkable growth, witness the impact of partnering with us on
                your journey to technological excellence. Your success story starts here.</p>
            </div>
          </div>
          <div class="col-md-4 d-flex" id="mainGate">
            <div class="slider">
              <div class="slide-track">

                <div class="slide" v-for="list in list" :key="list.id">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <img class="double" src="@/assets/double.svg" alt="" />
                      </div>
                      <p class="pt-2 txt">{{ list.review }}
                      </p>
                    </div>
                    <div class="cards-footer p-2">
                      <div class="row">
                        <div class=" text-center  col-2 py-4">
                          <img src="@/assets/blogs/google.png" class="  img-fluid" style="width: 70%;">
                        </div>
                        <div class="col-10">
                          <div class="d-flex flex-column  align-items-start justify-content-center">
                            <div class=" pt-3 name">{{ list.name }}</div>
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg></div>

                          </div>




                        </div>
                        <div class="col-">

                        </div>

                      </div>



                    </div>
                  </div>
                </div>
                <!-- <div class="slide">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <img class="double" src="@/assets/double.svg" alt="" />
                                        </div>
                                        <p class="pt-2 txt">Lorem ipsum dolor sit amet consectetur. Hac sociis
                                            viverra tincidunt risus est. Nisl varius sit velit sit.
                                            Id lorem eget maecenas ut nibh sed. Sed consequat odio
                                            proin odio velit a at at scelerisque. Aliquam purus
                                            facilisis cras justo cursus cursus suspendisse purus.
                                        </p>
                                        
                                        
                                    </div>
                                    <div class="cards-footer p-2">
                                        <div class="d-flex">
                                            <div class="float-start">
                                                <img
                                        class="clientImg pb-2 ps-1"
                                        src="@/assets/clientimage.svg"
                                        alt=""
                                    />
                                            </div>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <p class="ps-2 pt-3 name">User Name Goes Here</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->



              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex d-none d-md-block" id="mainGate">
            <div class="slider">
              <div class="slide-track2">/
                <div class="slide" v-for="list in list2" :key="list.id">

                  <div class="card">
                    <div class="card-body">
                      <div>
                        <img class="double" src="@/assets/double.svg" alt="" />
                      </div>
                      <p class="pt-2 txt">{{ list.review }}
                      </p>
                    </div>
                    <div class="cards-footer p-2">
                      <div class="row">
                        <div class=" text-center  col-2 py-4">
                          <img src="@/assets/blogs/google.png" class="  img-fluid" style="width: 70%;">
                        </div>

                        <div class="col-10">
                          <div class="d-flex flex-column align-items-start justify-content-start">
                            <div class="pt-3 name">{{ list.name }}</div>

                            <div><svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" fill="currentColor"
                                class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" fill="currentColor"
                                class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" fill="currentColor"
                                class="bi bi-star-fill  text-warning" viewBox="0 0 16 16">
                                <path
                                  d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg></div>

                          </div>




                        </div>
                        <div class="col-6">

                        </div>


                      </div>



                    </div>
                  </div>
                </div>
                <!-- <div class="slide" v-for="list in list" :key="list.id">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <img class="double" src="@/assets/double.svg" alt="" />
                      </div>
                      <p class="pt-2 txt">{{ list.review }}
                      </p>
                    </div>
                    <div class="cards-footer p-2">
                      <div class="d-flex">
                        <div class="float-start">
                          <img class="clientImg pb-2 ps-1" src={{image}} alt="googleimage" />
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                          <p class="ps-2 pt-3 name">{{ list.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of client-section -->

    <!-- solutions-section -->
    <section class="solutions-section py-md-5">
      <div class="container py-md-5">
        <h1 class="text-center heading">Beyond Solutions</h1>
        <p class="pb-3 text-center sub-heading">
          We don’t do marketing - we just love our clients
        </p>
        <div class="row px-lg-5 py-md-5">
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/ezye.jpg" alt="" class="img-fluid sol-img1" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/syora.jpg" alt="" class="img-fluid sol-img2" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/Amywood.jpg" alt="" class="img-fluid sol-img3" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/neo.jpg" alt="" class="img-fluid sol-img4" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/Bechdo.jpg" alt="" class="img-fluid sol-img5" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/jobsu.jpg" alt="" class="img-fluid sol-img6" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/Style.jpg" alt="" class="img-fluid sol-img7" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/cropped.jpg" alt="" class="img-fluid sol-img8" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/FJ.jpg" alt="" class="img-fluid sol-img9" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/face.jpg" alt="" class="img-fluid sol-img10" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/Thakur.jpg" alt="" class="img-fluid sol-img11" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/Seqronics.jpg" alt="" class="img-fluid sol-img12" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/Kaaya.jpg" alt="" class="img-fluid sol-img13" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/dream.jpg" alt="" class="img-fluid sol-img14" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/solray.jpg" alt="" class="img-fluid sol-img15" />
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex align-items-center justify-content-center mb-3">
            <div class="card">
              <img src="@/assets/myhome.jpg" alt="" class="img-fluid sol-img16" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of solutions-section -->

    <!-- insight-section -->
    <section class="insight-section pt-md-5 d-none d-lg-block">
      <div class="slider_container pt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <h1 class="pb-1 text-md-start text-center header">
                Unveiling Insights in IT Excellence
              </h1>
            </div>
            <div class="col-md-5">
              <div class="swiper-navigation">
                <div class="swiper-prev disabled">
                  <img src="@/assets/arrow1.png" alt="" class="insight-arrow1" />
                </div>
                <div class="swiper-next ps-2">
                  <img src="@/assets/arrow2.png" alt="" class="insight-arrow2" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper card_slider pt-3 pb-5">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="d-flex flex-column align-items-center justify-content-center ps-xl-4">
                  <div>
                    <div class="card p-2 mb-3">
                      <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <div class="  imgbox">
                            <img src="@/assets/blogs/blogsnew4.svg" alt="" class="rounded  img-fluid insight-img" />
                          </div>
                        </div>
                        <div class="col-md-6 p-0">
                          <div class="p-2">
                            <h6 class="card-head">
                              Mobile Commerce Revolution : Responsive E-commerce
                              by Digital Spike Technologies<br /><span class="date">02 Dec 2023</span>
                            </h6>
                            <span class="text">The surge in mobile device usage has ushered in a
                              revolution in the world of e-commerce. Mobile
                              commerce, or m-commerce, has become more than just
                              a...</span><br />
                            <a
                              href="https://insites.digitalspike.co/index.php/2024/01/02/mobile-commerce-revolutionresponsive-e-commerce-by-digital-spike-technologies/"><img
                                src="@/assets/insight-btn.png" alt="" class="img-fluid insight-btn-img" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card p-2 mb-3">
                      <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <div class=" imgbox">
                            <img src="@/assets/blogs/blogsnew2.svg" alt="" class="rounded  img-fluid insight-img" />
                          </div>
                        </div>
                        <div class="col-md-6 p-0">
                          <div class="p-2">
                            <h6 class="card-head">
                              Success in Your Palm: KeyFeatures of Mobile Apps
                              by Digital Spike Technologies<br /><span class="date">02 Dec 2023</span>
                            </h6>
                            <span class="text">In a world where smartphones have become
                              extensions of ourselves, the success of mobile
                              apps lies in their ability to seamlessly integrate
                              into our...</span><br />
                            <a
                              href="https://insites.digitalspike.co/index.php/2023/12/30/success-in-your-palm-keyfeatures-of-mobile-apps-by-digital-spike-technologies/"><img
                                src="@/assets/insight-btn.png" alt="" class="img-fluid insight-btn-img" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide pt-xl-3">
                <div class="d-flex flex-column align-items-center justify-content-center ps-xl-4">
                  <div>
                    <div class="card p-2 mb-3">
                      <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <div class=" imgbox">
                            <img src="@/assets/blogs/blogsnew3.svg" alt="" class="rounded img-fluid insight-img" />
                          </div>
                        </div>
                        <div class="col-md-6 p-0">
                          <div class="p-2">
                            <h6 class="card-head">
                              Digital Spike Technologies Secures Top Spot as
                              Best IT Company<br /><span class="date">02 Dec 2023</span>
                            </h6>
                            <span class="text">In the ever-evolving landscape of marketing,
                              digital strategies have become the cornerstone of
                              success. At the forefront of this revolution is
                              Digital Spike Technologies, a trailblazer in the
                              realm...</span><br />
                            <a
                              href="https://insites.digitalspike.co/index.php/2023/12/30/digital-spike-technologies-secures-top-spot-as-best-it-company/"><img
                                src="@/assets/insight-btn.png" alt="" class="img-fluid insight-btn-img" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card p-2 mb-3">
                      <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <div class=" imgbox">
                            <img src="@/assets/blogs/blogsnew.svg" alt="" class="rounded img-fluid insight-img" />
                          </div>
                        </div>
                        <div class="col-md-6 p-0">
                          <div class="p-2">
                            <h6 class="card-head">
                              Unveiling Excellence: The Impact of Digital Spike
                              Technologies in Marketing<br /><span class="date">02 Dec 2023</span>
                            </h6>
                            <span class="text">In the ever-evolving landscape of marketing,
                              digital strategies have become the cornerstone of
                              success. At the forefront of this revolution
                              is...</span><br />
                            <a
                              href="https://insites.digitalspike.co/index.php/2023/12/30/unveiling-excellence-the-impactof-digital-spike-technologies-inmarketing/"><img
                                src="@/assets/insight-btn.png" alt="" class="img-fluid insight-btn-img" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- mobile-view -->
    <section class="insight-section d-lg-none pt-md-5">
      <div class="slider_container pt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <h1 class="pb-1 text-md-start text-center header">
                Unveiling Insights in IT Excellence
              </h1>
            </div>
            <div class="col-md-5 d-none d-md-block">
              <div class="swiper-navigation">
                <div class="swiper-prev disabled">
                  <img src="@/assets/arrow1.png" alt="" class="insight-arrow1" />
                </div>
                <div class="swiper-next ps-2">
                  <img src="@/assets/arrow2.png" alt="" class="insight-arrow2" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper card_slider2 pt-3 pb-5">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="d-flex flex-column align-items-center justify-content-center ps-xl-4">
                  <div>
                    <div class="card p-2 mb-3">
                      <div class="row">
                        <div class="border-radious col-md-6 d-flex align-items-center justify-content-center">
                          <div class="border-radius imgbox">
                            <img src="@/assets/blogs/blogsnew.svg" alt="" class="img-fluid insight-img" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="p-2">
                            <h6 class="card-head">
                              Mobile Commerce Revolution:Responsive E-commerce
                              by Digital Spike Technologies<br /><span class="date">02 Dec 2023</span>
                            </h6>
                            <span class="text">The surge in mobile device usage has ushered in a
                              revolution in the world of e-commerce. Mobile
                              commerce, or m-commerce, has become more than just
                              a...</span><br />
                            <a
                              href="https://insites.digitalspike.co/index.php/2024/01/02/mobile-commerce-revolutionresponsive-e-commerce-by-digital-spike-technologies/"><img
                                src="@/assets/insight-btn.png" alt="" class="img-fluid insight-btn-img" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="d-flex flex-column align-items-center justify-content-center ps-xl-4">
                  <div>
                    <div class="card p-2 mb-3">
                      <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <div class="imgbox">
                            <img src="@/assets/blogs/blogsnew2.svg" alt="" class="img-fluid insight-img" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="p-2">
                            <h6 class="card-head">
                              Success in Your Palm: KeyFeatures of Mobile Apps
                              by Digital Spike Technologies<br /><span class="date">02 Dec 2023</span>
                            </h6>
                            <span class="text">In a world where smartphones have become
                              extensions of ourselves, the success of mobile
                              apps lies in their ability to seamlessly integrate
                              into our...</span><br />
                            <a
                              href="https://insites.digitalspike.co/index.php/2023/12/30/success-in-your-palm-keyfeatures-of-mobile-apps-by-digital-spike-technologies/"><img
                                src="@/assets/insight-btn.png" alt="" class="img-fluid insight-btn-img" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="d-flex flex-column align-items-center justify-content-center ps-xl-4">
                  <div>
                    <div class="card p-2 mb-3">
                      <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <div class="imgbox">
                            <img src="@/assets/blogs/blogsnew3.svg" alt="" class="img-fluid insight-img" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="p-2">
                            <h6 class="card-head">
                              Digital Spike Technologies Secures Top Spot as
                              Best IT Company<br /><span class="date">02 Dec 2023</span>
                            </h6>
                            <span class="text">In the ever-evolving landscape of marketing,
                              digital strategies have become the cornerstone of
                              success. At the forefront of this revolution is
                              Digital Spike Technologies, a trailblazer in the
                              realm......</span><br />
                            <a
                              href="https://insites.digitalspike.co/index.php/2023/12/30/digital-spike-technologies-secures-top-spot-as-best-it-company/"><img
                                src="@/assets/insight-btn.png" alt="" class="img-fluid insight-btn-img" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="d-flex flex-column align-items-center justify-content-center ps-xl-4">
                  <div>
                    <div class="card p-2 mb-3">
                      <div class="row">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <div class="imgbox">
                            <img src="@/assets/blogs/blogsnew4.svg" alt="" class="img-fluid insight-img" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="p-2">
                            <h6 class="card-head">
                              Unveiling Excellence: The Impact of Digital Spike
                              Technologies in Marketing<br /><span class="date">02 Dec 2023</span>
                            </h6>
                            <span class="text">In the ever-evolving landscape of marketing,
                              digital strategies have become the cornerstone of
                              success. At the forefront of this revolution
                              is...</span><br />
                            <a
                              href="https://insites.digitalspike.co/index.php/2023/12/30/unveiling-excellence-the-impactof-digital-spike-technologies-inmarketing/"><img
                                src="@/assets/insight-btn.png" alt="" class="img-fluid insight-btn-img" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of insight-section -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      list: [],
      list2: [],
    }
  },
  mounted() {
    var swiper = new Swiper(".card_slider", {
      slidesPerView: 2,
      spaceBetween: 30,
      loop: false,
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 1,
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      },
    });
    swiper.on("slideChange", function () {
      // Check if it's the last slide
      if (swiper.isEnd) {
        $(".swiper-next").addClass("disabled");
      } else {
        $(".swiper-next").removeClass("disabled");
      }

      // Check if it's the first slide
      if (swiper.isBeginning) {
        $(".swiper-prev").addClass("disabled");
      } else {
        $(".swiper-prev").removeClass("disabled");
      }
    });

    var swiper2 = new Swiper(".card_slider2", {
      slidesPerView: 2,
      spaceBetween: 30,
      loop: false,
      autoplay: {
        delay: 2000,
      },
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 1,
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      },
    });
    swiper2.on("slideChange", function () {
      // Check if it's the last slide
      if (swiper.isEnd) {
        $(".swiper-next").addClass("disabled");
      } else {
        $(".swiper-next").removeClass("disabled");
      }

      // Check if it's the first slide
      if (swiper.isBeginning) {
        $(".swiper-prev").addClass("disabled");
      } else {
        $(".swiper-prev").removeClass("disabled");
      }
    });

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbzYiEqkkc1Nd-DlDPKpUaGTpYklzUSm16NtiH_FSBYsCwYKyWozMPJJRQZArp0XEf9atA/exec";
    const form = document.forms["submit-to-google-sheet"];

    form.addEventListener("submit", (e) => {
      e.preventDefault();
      fetch(scriptURL, { method: "POST", body: new FormData(form) })
        .then(
          (response) => console.log("Success!", response),
          $("#newsform")[0].reset(),
          $("#email-modal").modal("show"),
          $(".modal-backdrop").hide(),

          setTimeout(function () {
            $("#email-modal").modal("hide");
          }, 2000)
        )
        .catch((error) => console.error("Error!", error.message));
    });
    axios.get('https://digitalspike.co/digitalReviews/review.php')
      .then((response) => {
        this.list = response.data; 
        // console.log(this.list);
        // console.log(response);
      }).catch((error) => {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          if (error.response.status == 400) {
            Swal.fire({
              icon: "error",
              title: "User Not Found!",
              showConfirmButton: false,
              timer: 2000,
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "Invalid Credentials!",
              showConfirmButton: false,
              timer: 2000,
            })
          }
          console.log("Response data:", error.response.data)
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
    // list 2//
    axios.get('https://digitalspike.co/digitalReviews/review2.php', {

    })
      .then((response) => {
        this.list2 = response.data;
        // console.log(this.list2)/
        // console.log(response)

      }).catch((error) => {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          if (error.response.status == 400) {
            Swal.fire({
              icon: "error",
              title: "User Not Found!",
              showConfirmButton: false,
              timer: 2000,
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "Invalid Credentials!",
              showConfirmButton: false,
              timer: 2000,
            })
          }
          console.log("Response data:", error.response.data)
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });

  }
}

</script>

<style scoped>
.main {
  overflow-x: hidden;
}

/* navbar-section */
.logo {
  width: 160px;
  height: 50px;
}

nav .nav-link {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

nav .active {
  color: rgba(39, 133, 255, 1) !important;
}

nav .download {
  background: rgba(13, 31, 15, 1);
}

nav .btn-txt {
  background: linear-gradient(90deg,
      rgba(51, 146, 238, 1) 2%,
      rgba(115, 227, 133, 1) 73%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}

nav .btn-txt:hover {
  background: linear-gradient(90deg,
      rgba(115, 227, 133, 1) 2%,
      rgba(51, 146, 238, 1) 73%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}

@media (max-width: 767.98px) {
  .d {
    padding-top: 14px;
  }
}

/* sub-navbar-section */
.subnav-section {
  margin: 0px 60px;
}

.subnav-class a {
  color: rgba(1, 13, 38, 1);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.subnav-class header .tabs {
  display: flex;
  overflow-y: auto;
}

.subnav-class header .tabs a img {
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.subnav-class header .tabs a {
  display: block;
  padding: 0px 13px 13px 30px;
  font-weight: 900;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
  cursor: pointer;
}

.subnav-class .tabs::-webkit-scrollbar {
  height: 0;
}

.subnav-class header .tabs a.router-link-active {
  border-bottom: 2px solid #73e386;
}

@media screen and (max-width: 992px) {
  .subnav-section {
    margin: 0px 0px;
  }
}

/* hero-section */

.hero {
  background-image: url("~@/assets/hero.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hero .heading {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
}

.star-top {
  max-width: 80px;
}

.newsletter {
  position: relative;
  /* display: flex;
    align-items: start;
    justify-content: start; */
}

.newsletter .newsletter-form {
  position: relative;
  max-width: 355px;
  margin-top: 23px;
  z-index: 999;
}

.newsletter .newsletter-form input {
  height: 55px;
  width: 100%;
  padding: 20px 135px 17px 20px;
  border: none;
  border-radius: 10px;
  outline: none;
  background-color: #ffffff;
  color: rgba(133, 133, 133, 1);
  font-family: "Mulish";
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.newsletter .newsletter-form button {
  width: 120px;
  height: 38px;
  border-radius: 5px;
  background-color: #000;
  font-size: 0.875rem;
  line-height: 1;
  position: absolute;
  right: 9px;
  bottom: 9px;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-family: "Montserrat";
  font-weight: 600;
}

.since-text {
  color: rgba(16, 16, 16, 1);
  font-family: "Mulish";
  font-weight: 600;
  letter-spacing: 1.2px;
}

.smallStar {
  width: 60px;
  height: 70px;
  position: absolute;
  top: 210px;
  left: 30px;
  z-index: -1;
}

.bigStar {
  width: 85px;
  height: 95px;
  position: absolute;
  bottom: 240px;
  right: 250px;
  z-index: -1;
}

.box-card {
  width: 300px;
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 10px;
  border: none;
}

.since-header {
  color: #73e386;
  font-family: "Montserrat";
  font-weight: 700;
}

.project-header {
  color: #2785ff;
  font-family: "Montserrat";
  font-weight: 700;
}

.employees-header {
  color: #ee7e1b;
  font-family: "Montserrat";
  font-weight: 700;
}

@media (max-width: 768px) {
  .smallStar {
    width: 60px;
    height: 70px;
    position: absolute;
    top: 20px;
    left: 110px;
  }

  .bigStar {
    width: 85px;
    height: 95px;
    position: absolute;
    bottom: 30px;
    right: 140px;
  }
}

/* service-section */
.service-section {
  background: radial-gradient(circle farthest-corner at center center,
      rgb(146, 189, 245) 0%,
      #ffffff 40%);
}

.service-section .card {
  width: 390px;
  height: 550px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 10px;
  border: none;
}

.service-section .card-header {
  background: transparent !important;
  border: none;
  padding-bottom: 0px !important;
}

.service-section .card-footer {
  background: transparent !important;
  border: none;
}

.service-section .card .card-title {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
  font-weight: 600;
}

.service-section .card .card-text {
  color: rgba(114, 114, 114, 1);
  font-family: "Mulish";
  font-weight: 400;
}

.service-section .heading {
  font-family: "Montserrat";
  color: rgba(1, 13, 38, 1);
  font-weight: 700;
}

.service-section .service-img {
  width: 80px;
}

.service-section .service-btn-img {
  width: 120px;
}

/* choose-use-section */
.why-section {
  background-image: url("~@/assets/choose-bg.png");
  background-repeat: no-repeat;
  background-position: left !important;
}

.why-header {
  font-weight: 700;
  font-family: "Montserrat";
  color: rgba(1, 13, 38, 1);
}

.why-section .sub-header {
  font-weight: 600;
  font-family: "Mulish";
  color: rgba(16, 16, 16, 1);
}

.why-section .txt {
  color: rgba(114, 114, 114, 1);
  font-weight: 400;
  font-family: "Mulish";
  font-size: 18px;
}

.star-img {
  width: 22px;
  height: 22px;
}

/* competencies-section */
.competencies-section {
  background: rgba(13, 13, 13, 1);
}

.competencies-section .heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}

.competencies-section .sub-heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: rgba(133, 133, 133, 1);
}

.competencies-section .card {
  width: 350px;
  height: 250px;
  border-radius: 20px;
  background: rgba(16, 16, 16, 1);
}

.competencies-section .card img {
  max-width: 46px !important;
}

.competencies-section .card-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.competencies-section .card-text {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: rgba(133, 133, 133, 1);
}

.competencies-section .card-header-img {
  width: 46px;
  height: 46px;
  border-radius: 10px;
  background: rgba(115, 227, 134, 1);
  padding: 5px;
}

.competencies-section .card:hover .card-header-img {
  width: 46px;
  height: 46px;
  border-radius: 10px;
  background: rgba(16, 16, 16, 1);
  padding: 5px;
}

.competencies-section .card:hover .card-header-img .comp {
  filter: brightness(0) invert(1);
}

.competencies-section .card:hover {
  background: rgba(115, 227, 134, 1);
  filter: drop-shadow(30px 30px 80px rgba(115, 227, 134, 1));
}

@media (min-width: 1200px) {
  .competencies-section .shadow-card {
    background: rgba(115, 227, 134, 1);
    filter: drop-shadow(30px 30px 80px rgba(115, 227, 134, 1));
  }

  .competencies-section .shadow-card .card-title {
    color: rgba(16, 16, 16, 1);
  }

  .competencies-section .shadow-card .card-text {
    color: rgba(38, 88, 47, 1) !important;
  }

  .competencies-section .shadow-card .card-header-img {
    background: rgba(16, 16, 16, 1) !important;
  }

  .competencies-section .shadow-card .card-header-img .comp {
    filter: brightness(0) invert(1);
  }
}

.competencies-section .card:hover .card-title {
  color: rgba(16, 16, 16, 1);
}

.competencies-section .card:hover .card-text {
  color: rgba(38, 88, 47, 1) !important;
}

/* client-section */
.client-section {
  background-image: url("~@/assets/client-bg.png");
  background-repeat: no-repeat;
  background-position: left !important;
}

.client-section .heading {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
  font-weight: 700;
}

.client-section .sub-txt {
  color: rgba(114, 114, 114, 1);
  font-family: "Mulish";
  font-weight: 400;
}

.client-section .txt {
  color: rgba(1, 13, 38, 1);
  font-family: "Mulish";
  font-weight: 400;
}

.client-section .name {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
  font-weight: 600;
}

.client-section .double {
  width: 25px;
}

.client-section .clientImg {
  width: 60px;
  height: 60px;
}

.client-section .slider {
  height: 750px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  /* place-items: center; */
}

.client-section .slide-track {
  /* display: flex; */
  height: 300px;
  width: calc(100%);
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(-250px));
  }
}

.client-section .slide-track2 {
  /* display: flex; */
  height: 300px;
  width: calc(100%);
  margin-top: -300px;
  animation: scroll2 10s linear infinite;
}

@keyframes scroll2 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(250px));
  }
}

.client-section .slide {
  height: auto;
  width: 100%;
  display: flex;
  /* align-items: center; */
  padding: 13px;
  perspective: 100px;
  /* margin-bottom: 50px; */
}

.client-section img {
  width: 100%;
  transition: transform 1s;
}

.client-section img:hover {
  transform: translateZ(20px);
}

.client-section .slider::before,
.client-section .slider::after {
  background: linear-gradient(to right,
      rgba(255, 255, 255, 1) 0%,
      rbga(255, 255, 255, 0) 100%);
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.client-section .slider::before {
  left: 0;
  top: 0;
}

.client-section .slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.client-section #mainGate {
  overflow: scroll;
}

.client-section .card {
  border-radius: 20px !important;
  background: rgb(239, 242, 250);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border: none;
}

.client-section .card .cards-footer {
  background: #fff;
  border-radius: 0px 0px 20px 20px !important;
}

.client-section #mainGate::-webkit-scrollbar {
  height: 0;
}

@media screen and (max-width: 768px) {
  .client-section #mainGate {
    width: 100%;
    height: auto;
  }

  .client-section .slide-track {
    display: flex;
    justify-content: space-evenly;
    height: 300px;
    width: 260%;
    animation: scroll 0s linear infinite;
  }

  .client-section .slide {
    width: 100%;
    /* margin-bottom: 50px; */
    margin-right: 10px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px));
    }
  }

  .client-section .slide-track {
    /* display: flex; */
    animation: scroll3 10s linear infinite;
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px));
    }
  }
}

/* solutions-section */
.solutions-section {
  background-image: url("~@/assets/Subtract.png");
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 767.98px) {
  .solutions-section {
    background-position: center center;
  }
}

.solutions-section .heading {
  color: rgba(115, 227, 134, 1);
  font-family: "Montserrat";
  font-weight: 700;
}

.solutions-section .sub-heading {
  color: rgba(16, 16, 16, 1);
  font-family: "Mulish";
  font-weight: 600;
}

.solutions-section .card {
  /* display: flex;
    align-items: center;
    justify-content: center; */
  width: 250px;
  height: 100px;
  margin-bottom: 14px;
  background: transparent;
  border: none;
  box-shadow: none !important;
}

.solutions-section .sol-img1 {
  width: 140px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img2 {
  width: 200px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img3 {
  width: 300px;
  height: 140px;
  /* object-fit: contain; */
}

.solutions-section .sol-img4 {
  width: 400px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img5 {
  width: 160px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img6 {
  width: 160px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img7 {
  width: 170px;
  height: 100px;
  /* object-fit: contain; */
}

.solutions-section .sol-img8 {
  width: 200px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img9 {
  width: 210px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img10 {
  width: 180px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img11 {
  width: 190px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img12 {
  width: 250px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img13 {
  width: 200px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img14 {
  width: 220px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img15 {
  width: 230px;
  height: 100px;
  object-fit: contain;
}

.solutions-section .sol-img16 {
  width: 150px;
  height: 100px;
}

/* insight-section */
.insight-section {
  background-image: url("~@/assets/insight-bg.png");
  background-repeat: no-repeat;
  background-position: right !important;
}

.insight-section .swiper-navigation {
  display: flex;
  align-items: center;
  justify-content: end;
}

.insight-section .swiper-next,
.insight-section .swiper-prev {
  cursor: pointer;
  margin: 0 5px;
}

.insight-section .swiper-next img,
.insight-section .swiper-prev img {
  width: 25px;
  height: 25px;
}

.insight-section .swiper-next.disabled,
.insight-section .swiper-prev.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.insight-section .insight-arrow1:hover {
  transform: scaleX(1.2);
  transform-origin: right 0%;
}

.insight-section .insight-arrow2:hover {
  transform: scaleX(1.2);
  transform-origin: 0% 0%;
}

.insight-section .swiper-slide .card {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border: none;
}

.insight-section .insight-btn-img {
  max-width: 100px;
}

.insight-section .imgbox {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.insight-section .imgbox img {
  height: 100% !important;
  max-width: 100% !important;
}

.insight-section .header {
  color: rgba(16, 16, 16, 1);
  font-family: "Montserrat";
  font-weight: 700;
}

.insight-section .card-head {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
  font-weight: 600;
}

.insight-section .date {
  font-size: small;
  color: rgba(39, 133, 255, 1);
  font-weight: 400;
  font-family: "Mulish";
}

.insight-section .text {
  font-size: 16px;
  font-weight: 400;
  font-family: "Mulish";
  color: rgba(133, 133, 133, 1);
}

@media (max-width: 767.98px) {
  .insight-section .cards {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .insight-section .cards {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .hero {
    background-size: cover !important;
  }

  .client-section .slider {
    height: 410px;
  }
}

.hero-cards {
  position: relative;
  top: 100px;
}

@media (max-width: 575.98px) {
  .solutions-section .sol-img1 {
    width: 110px;
    height: 90px;
    object-fit: contain;
  }

  .solutions-section .sol-img2 {
    width: 150px;
    height: 80px;
    object-fit: contain;
  }

  .solutions-section .sol-img3 {
    width: 150px;
    height: 120px;
    /* object-fit: contain; */
  }

  .solutions-section .sol-img4 {
    width: 300px;
    height: 80px;
    object-fit: contain;
  }

  .solutions-section .sol-img5 {
    width: 140px;
    height: 90px;
    object-fit: contain;
  }

  .solutions-section .sol-img6 {
    width: 120px;
    height: 80px;
    object-fit: contain;
  }

  .solutions-section .sol-img7 {
    width: 120px;
    height: 80px;
    /* object-fit: contain; */
  }

  .solutions-section .sol-img8 {
    width: 200px;
    height: 90px;
    object-fit: contain;
  }

  .solutions-section .sol-img9 {
    width: 180px;
    height: 90px;
    object-fit: contain;
  }

  .solutions-section .sol-img10 {
    width: 150px;
    height: 80px;
    object-fit: contain;
  }

  .solutions-section .sol-img11 {
    width: 170px;
    height: 90px;
    object-fit: contain;
  }

  .solutions-section .sol-img12 {
    width: 250px;
    height: 100px;
    object-fit: contain;
  }

  .solutions-section .sol-img13 {
    width: 180px;
    height: 90px;
    object-fit: contain;
  }

  .solutions-section .sol-img14 {
    width: 200px;
    height: 90px;
    object-fit: contain;
  }

  .solutions-section .sol-img15 {
    width: 230px;
    height: 100px;
    object-fit: contain;
  }

  .solutions-section .sol-img16 {
    width: 140px;
    height: 90px;
  }
}

#email-modal .modal-dialog .modal-content {
  border-radius: 20px !important;
}

@media (min-width:300px) {
  .google-img {
    width: 12% !important;
  }
}

@media (max-width:300px) {
  .google-img {
    width: 6% !important;
  }
}
</style>