<template>
  <div class="main">

    <!-- hero-section -->
    <section class="hero pt-md-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 d-flex align-items-center justify-content-center pt-md-5">
                    <div>
                        <img src="@/assets/home2/main-logo.png" alt="" class="img-fluid hero-img mt-3"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pt-md-5 d-flex flex-column">
                        <div class="float-start pt-md-5">
                        <img src="@/assets/smallStar.svg" alt="" class="star-top img-fluid">
                        </div>
                        <h1 class="pt-3 pb-2 heading text-md-start text-center">Step into the <br><span style="color: rgba(19, 89, 187, 1) !important; font-weight: 700;"> Future </span>of Websites<br> with us!</h1>
                        <div class="pt-2">
                            <div class="d-flex align-items-md-start justify-content-md-start align-items-center justify-content-center">
                              <div class="download">
                <button type="button" class="btn btn-download btn-txt">Build Your Presence Now</button>
              </div>
              </div>
                            <div class="container">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="float-end">
                                                <img src="@/assets/bigStar.svg" alt="" class="star-bottom img-fluid">
                                            </div>
                                        </div>
                                        <div class="col-md-3"></div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end of hero-section -->

    <!-- process-section -->
    <section class="our-process py-5 d-none d-md-block">
      <div class="container py-md-5">
        <div class="pb-5">
          <h1 class="text-center heading">Our Process</h1>
          <p class="sub-heading text-center">  process of designing and developing a website and mobile app involves various stages, including conceptualization, design, development, testing, and deployment.</p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img src="@/assets/ourprocess-1.png" alt="" class="img-fluid process-img1">
          </div>
        </div>
      </div>
    </section>
    <section class="our-process py-5 d-md-none">
      <div class="container px-5 py-md-5">
        <div class="pb-5">
          <h1 class="text-center heading">Our Process</h1>
          <p class="sub-heading text-center">  process of designing and developing a website and mobile app involves various stages, including conceptualization, design, development, testing, and deployment.</p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img src="@/assets/ourprocess2.png" alt="" class="img-fluid process-img1">
          </div>
        </div>
      </div>
    </section>
    <!-- end of process-section -->

    <!-- service-section-1 -->
    <section class="service-section-1 pb-5">
      <div class="container px-5">
        <h1 class="text-center pt-5 service-header">Website Development</h1>
        <p class="text-center pb-5">
          So where do you start? We give <b style="color: black;">Best Website development services</b>  Unlock Your Potential: Building a Website from the Ground Up is Just the Start.<br> The Real 'Game' Begins When You Scale and Grow Beyond Your Comfort Zone<br>We'll Help You Reach Your Goals.
        </p>
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web1.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex flex-column align-items-center justify-content-center mb-3">
            <div>
              <h1 class="pb-2 service-heading display-5 text-md-start text-center">All-in-One Excellence</h1>
              <p class="sub-heading text-md-start text-center">  Experience the All-in-One Excellence: Our skilled team provides comprehensive solutions, from captivating static pages to cutting-edge web apps, ensuring an unmatched user experience.</p>
              
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-1 -->

    <!-- service-section-2 -->
    <section class="service-section-2 py-md-3 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Cost-Effective<br> Brilliance</h1>
              <p class="sub-heading text-md-start text-center">Breakthrough solutions that not only meet but exceed your expectations—all within your budget.</p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web2.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-2 -->

    <!-- service-section-3 -->
    <section class="service-section-3 py-md-3 pb-5">
      <div class="container px-5">
        <div class="row ">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web3.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="pt-md-5">
              <h1 class="pt-md-5 pb-2 service-heading display-5 text-md-start text-center">Agile Speed, No Compromise</h1>
              <p class="sub-heading text-md-start text-center">Embrace agility with us—swift development, high-quality code, and seamless adaptability.</p>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-3 -->

    <!-- service-section-4 -->
    <section class="service-section-4 py-md-3 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
           <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Bug-Free Assurance</h1>
              <p class="sub-heading text-md-start text-center">Meticulous testing ensures flawlessness, giving you a product ready for success from day one</p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web4.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
         
        </div>
      </div>
    </section>
    <!-- end of service-section-4 -->

    <!-- service-section-5 -->
    <section class="service-section-5 py-md-3 pb-5">
      <div class="container px-5">
        <div class="row ">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web5.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">In-House Expertise</h1>
              <p class="sub-heading text-md-start text-center">Our seasoned developers ensure your project reaches new heights, surpassing industry standards.</p>
              
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-5 -->

    <!-- service-section-6 -->
    <section class="service-section-6 py-md-3 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
            <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Confidentiality Assured</h1>
              <p class="sub-heading text-md-start text-center">Trust us with your ideas. Our Non-Disclosure Agreement keeps your sensitive data secure.</p>         
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web6.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <!-- end of service-section-6 -->

    <!-- service-section-7 -->
    <section class="service-section-7 py-md-3 pb-5">
      <div class="container px-5">
        <div class="row ">
            <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web7.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">UI/UX Mastery</h1>
              <p class="sub-heading text-md-start text-center">Immerse your audience in unforgettable experiences crafted with the latest design techniques.</p>
              
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <!-- end of service-section-7 -->

 <!-- service-section-8 -->
    <section class="service-section-8 py-md-3 pb-5">
      <div class="container px-5">
        <div class="row flex-wrap-reverse">
           <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">Transparency Matters</h1>
              <p class="sub-heading text-md-start text-center">Regular updates and open communication guarantee you're always in the loop.</p>
            
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web8.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
         
        </div>
      </div>
    </section>
    <!-- end of service-section-8 -->

    <!-- service-section-9 -->
    <section class="service-section-9 py-md-3 pb-5">
      <div class="container px-5">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <img src="@/assets/home2/web9.png" alt="" class="img-fluid hero-img"/>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mb-3">
            <div>
              <h1 class="service-heading display-5 pb-2 text-md-start text-center">On-Time, Every Time</h1>
              <p class="sub-heading text-md-start text-center">We don't just promise; we deliver ahead of schedule, ensuring your success waits for no one.</p>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- end of service-section-9 -->

  </div>
</template>

<script>
export default {
  name:"websitedevelopment",
}
</script>

<style scoped>
  .main {
    overflow-x: hidden;
  }
/* navbar-section */
#dst-logo{
  width: 160px;
  height: 50px;
}
nav .nav-link {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
nav a.router-link-active {
    color: rgba(39, 133, 255, 1);
}
nav .download {
    background: rgba(13, 31, 15, 1);
}
nav .btn-txt {
    background: linear-gradient(
    90deg,
    rgba(51, 146, 238, 1) 2%,
    rgba(115, 227, 133, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
nav .btn-txt:hover {
    background: linear-gradient(
    90deg,
    rgba(115, 227, 133, 1) 2%,
    rgba(51, 146, 238, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
@media (max-width: 767.98px) {
    .d{
        padding-top: 14px;
    }
}
/* sub-navbar-section */
.subnav-section{
  margin: 0px 60px;
}
.subnav-class a{
    color: rgba(1, 13, 38, 1);
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
.subnav-class header .tabs{
    display: flex;
    overflow-y: auto;
}

.subnav-class header .tabs a img{
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.subnav-class header .tabs a{
    display: block;
    padding: 0px 13px 13px 30px;
    font-weight: 900;
    outline: none;
    white-space: nowrap;
    text-decoration: none;
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
    cursor: pointer;
}

.subnav-class .tabs::-webkit-scrollbar {
    height: 0;
}

.subnav-class header .tabs a.router-link-active{
   
    border-bottom: 2px solid #73E386;
}
@media screen and (max-width:992px) {
    .subnav-section{
  margin: 0px 0px;
}
}
/* hero-section */
.hero .download {
    background: rgba(13, 31, 15, 1);
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero .btn-txt {
    background: linear-gradient(
    90deg,
    rgba(51, 146, 238, 1) 2%,
    rgba(115, 227, 133, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
.hero .btn-txt:hover {
    background: linear-gradient(
    90deg,
    rgba(115, 227, 133, 1) 2%,
    rgba(51, 146, 238, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}
.hero .heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
}
.hero {
    background-image: url("~@/assets/home2/web-bg-1.png"), url("~@/assets/home2/web-bg-2.png");
    background-repeat: no-repeat;
    background-position: left center, right top !important;
}
@media (max-width: 767.98px) {
    .hero {
        background-position: left !important;
    }
}
/* our-process-section */
.our-process .sub-heading {
  color : rgba(114, 114, 114, 1);
  font-family: 'Montserrat';
  font-weight: 400;
}
.our-process .heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
/* service-section */
.service-header {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
.service-heading {
  color: rgba(1, 13, 38, 1);
  font-family: 'Montserrat';
  font-weight: 700;
}
.sub-heading {
  color: rgba(61, 70, 80, 1);
  font-family: 'Mulish';
  font-weight: 400;
}
.service-section-1 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-2 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-3 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-4 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-5 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-6 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-7 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}
.service-section-8 {
    background-image: url("~@/assets/home2/sec1.png");
    background-repeat: no-repeat;
    background-position: left !important;
}
.service-section-9 {
    background-image: url("~@/assets/home2/sec2.png");
    background-repeat: no-repeat;
    background-position: right !important;
}


/* faq-section */
.faq-design {
    background-image: url("~@/assets/faq-bg.png"), url("~@/assets/home2/faqbg.png");
    background-repeat: no-repeat;
    background-position: left center, right top !important;
}
.faq-design .question {
  font-weight: 700;
  cursor: pointer;
}
.faq-design .heading {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.faq-design .faq-box:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.far.fa-plus-square,
.far.fa-minus-square {
  color: #2785ff;
}
.faq-arrow {
    max-width: 200px;
}
.faq-design .question {
    color: rgba(16, 16, 16, 1) !important;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: larger;
}
.faq-design .collapse {
    color: rgba(16, 16, 16, 1) !important;
    font-family: 'Montserrat';
    font-weight: 400;
}
.faq-design .faq-text {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: 'Open Sans';
}
@media (max-width: 767.98px) {
    .faq-box{
        padding: 0px 16px;
    }
}
/* contact-section */
.contact-section {
    background-image: url("~@/assets/contact-bg.png");
    background-repeat: no-repeat;
    background-position: right !important;
    color: #000;
    font-family: "Exo", sans-serif;
}
.contact-section .contact-links {
  font-weight: 600;
}
.contact-section .contact-img {
    max-width: 300px;
}
.contact-section .form-cover {
    width: 86%;
}
.contact-section .form-control {
  font-size: 15px !important;
  color: #858585;
  font-family: Mulish;
  border: 1.5px solid #d1d3db80;
}
.contact-section .form-control:focus {
  box-shadow: none;
}
.contact-section .submitBtn {
    background: #2289fe;
    color: rgba(255, 255, 255, 1);
    border: none;
    font-family: 'Montserrat';
    font-weight: 600;
}
.contact-section .heading {
    color: rgba(16, 16, 16, 1);
    font-family: 'Montserrat';
    font-weight: 700;
}
.contact-section label {
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(18, 18, 18, 1);
}
.contact-section input {
    font-family: 'Mulish';
    font-weight: 400;
    color: rgba(133, 133, 133, 1);
}
/* footer-section */
.footer .links-header {
    color: rgba(39, 133, 255, 1);
    font-family: 'Montserrat';
    font-weight: 600;
}
.footer .links-item {
    color: rgba(1, 13, 38, 1);
    font-family: 'Mulish';
    font-weight: 500;
}
.footer .copyright {
    color: rgba(1, 13, 38, 1);
    font-family: 'Montserrat';
    font-weight: 400;
}
.footer .copyright-conditions {
    color: rgba(0, 52, 109, 1);
    font-family: 'Montserrat';
    font-weight: 500;
}
@media (max-width: 767.98px) { 
    .contact-section {
        background-position: center !important;
    }
}
</style>