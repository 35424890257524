<template>
  <div id="app">
    <div
      class="modal fade"
      id="email-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            
            <div>
              <section
                class="clock p-1 d-flex align-items-center justify-content-center"
              >
                <div class="p-4" style="width: 25rem; border-radius: 20px">
                  <div
                    class="card-body d-flex flex-column align-items-center justify-content-center"
                  >
                    <div class="py-3 px-2">
                      <img
                        src="@/assets/Group 136.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <h3 class="verify-btn py-2 lh-sm mb-0 text-center">
                      Grateful for Your Contact!
                    </h3>
                    <div class="pb-2 color">
                      <p class="text-center">
                        Thank You for Reaching Out to Us.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <section class="popup-modal">
      <div
        class="modal fade"
        id="exampleModals"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalsLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header ">
        <button
              type="button"
              class="btn-close btn-close-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
      </div>
            <div class="modal-body">
              <div class="popup-container container py-2">
                <div class="row">
                  <div
                    class="col-lg-6 d-flex flex-column align-items-center justify-content-center con"
                  >
                    <div class="contact-us-img">
                      <h3 class="text-center heading">
                        Reach Out to Elevate Your Tech Journey
                      </h3>
                      <div class="d-flex justify-content-center py-2">
                        <div class="floating-icon">
                          <div class="icons">
                            <ul class="d-flex">
                              <div class="pe-3">
                                <a
                                  href="https://wa.me/9008983396"
                                  target="_blank"
                                  aria-label="To know more Whatsapp Us"
                                >
                                  <li class="whatsapp text-dark">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="1.0em"
                                      viewBox="0 0 448 512"
                                    >
                                      <path
                                        fill="black"
                                        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                                      />
                                    </svg>
                                    <span
                                      class="ps-2"
                                      style="
                                        font-size: 16px;
                                        color: #000 !important;
                                      "
                                      >WHATSAPP</span
                                    >
                                  </li>
                                </a>
                              </div>
                              <div>
                                <a
                                  href="tel:+919008983396"
                                  aria-label="To know more Call Us"
                                >
                                  <li class="phone text-dark">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="0.8em"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="white"
                                        d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                                      />
                                    </svg>
                                    <span
                                      class="ps-2"
                                      style="
                                        font-size: 16px;
                                        color: #fff !important;
                                      "
                                      >CALL US</span
                                    >
                                  </li>
                                </a>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="px-5">
                        <img
                          src="@/assets/blogs/foams2img.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 d-flex align-items-start justify-content-center pt-md-3"
                  >
                    <div class="form-cover w-100">
                      <form v-on:submit.prevent="onSubmit()" id="myForm">
                        <fieldset>
                          <div class="text mb-3">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                              >Name</label
                            >
                            <input
                              v-model="username"
                              type="text"
                              class="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter your name"
                              required
                            />
                          </div>
                          <div class="text mb-3">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                              >Email Address</label
                            >
                            <input
                              v-model="email"
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter your email address"
                              required
                            />
                          </div>
                          <div class="text mb-3">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                              >Mobile Number</label
                            >
                            <input
                              v-model="phone"
                              type="text"
                              class="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter your mobile number"
                              @keypress="restrictChars($event)"
                              maxlength="10"
                              required
                            />
                          </div>
                          <div class="text mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                              >Message</label
                            >
                            <textarea
                              v-model="message"
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="Write a message"
                            ></textarea>
                          </div>
                          <div class="pt-2">
                            <button
                              type="submit"
                              class="submitBtn btn w-100 text-uppercase bg-primary"
                            >
                              Submit
                            </button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- navbar-section -->
    <nav
      class="navbar navbar-expand-md navbar-light bg-light p-0"
      style="background: #fff !important"
    >
      <div class="container-md pt-md-2 pt-2">
        <a class="navbar-brand" href="/">
          <img
            src="@/assets/DigitalSpikelogo.png"
            alt=""
            class="logo img-fluid"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasDarkNavbar"
          aria-controls="offcanvasDarkNavbar"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="sidebar offcanvas offcanvas-start text-bg-white"
          tabindex="-1"
          id="offcanvasDarkNavbar"
          aria-labelledby="offcanvasDarkNavbarLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">
              Features
            </h5>
            <button
              type="button"
              class="btn-close btn-close-dark"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body flex-lg-row p-4 p-lg-0">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li class="nav-item mx-lg-3 mx-md-2">
                <a href="/" class="nav-link">Home</a>
              </li>
              <li class="nav-item mx-lg-3 mx-md-2">
                <a href="https://insites.digitalspike.co/" class="nav-link"
                  >Insights</a
                >
              </li>
              <li class="nav-item mx-lg-3 mx-md-2">
                <a href="/career" class="nav-link">Career</a>
              </li>
              <li class="nav-item mx-lg-3 mx-md-2">
                <a href="/about" class="nav-link">About Us</a>
              </li>
            </ul>
            <div class="d">
              <a href="/initiate-collaboration">
                <div class="download">
                  <button type="button" class="btn btn-download btn-txt">
                    Initiate Collaboration
                  </button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- end of navbar-section -->

    <hr />

    <!-- sub-navbar-section -->
    <section class="subnav-section">
      <div class="container-md container-fluid subnav-class" id="subnav-class">
        <header class="d-flex">
          <div class="d-xl-none">
            <img @click="slide('left')" src="@/assets/leftArrow.svg" alt="" />
          </div>
          <div class="tabs" ref="tabsContainer">
            <router-link
              tabindex="0"
              id="my"
              class="nav-link"
              to="/digital_marketing"
              ><img src="@/assets/digitalMarketing.svg" alt="" /> Digital
              Marketing
            </router-link>
            <router-link tabindex="0" class="nav-link" to="/website_development"
              ><img src="@/assets/websiteDevelopments.svg" alt="" /> Website
              Developements</router-link
            >
            <router-link tabindex="0" class="nav-link" to="/web_application"
              ><img src="@/assets/webapplicationDevelopment.svg" alt="" /> Web
              Application Development</router-link
            >
            <router-link tabindex="0" class="nav-link" to="/mobile_application"
              ><img src="@/assets/mobileApp.svg" alt="" />MobileApplication
              Development</router-link
            >
            <router-link tabindex="0" class="nav-link" to="/ui_ux"
              ><img src="@/assets/ui_ux.svg" alt="" />UI/UX Design</router-link
            >
          </div>
          <div class="d-xl-none">
            <img @click="slide('right')" src="@/assets/rightArrow.svg" alt="" />
          </div>
        </header>
      </div>
    </section>
    <!-- end of sub-navbar-section -->

    <!-- floating icons -->
    <section class="floating-icons">
      <div class="icons">
        <ul>
          <div>
            <a
              href="https://wa.me/9008983396"
              target="_blank"
              aria-label="To know more Whatsapp Us"
            >
              <li class="whatsapp">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.1em"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="white"
                    d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                  />
                </svg>
              </li>
            </a>
          </div>
        </ul>
      </div>
    </section>
    <!-- floating icons -->

    <!-- router component -->
    <router-view />
    <!-- router component -->

    <!-- FAQ-section -->
    <section
      v-if="
        $route.path !== '/career' &&
        $route.path !== '/privacy-policy' &&
        $route.path !== '/terms&conditions' &&
        $route.path !== '/initiate-collaboration'
      "
      class="faq-design"
    >
      <div class="container py-5">
        <h1 class="text-center pb-3 heading">
          Your Quick Guide to Tech Clarity
        </h1>

        <div class="row">
          <div class="col-12 col-lg-2 col-sm-12 mb-3 mb-lg-0">
            <div class="backgroundgreen"></div>
          </div>
          <div class="col-12 col-lg-8 col-sm-12">
            <div class="padding-part">
              <div
                v-for="(faq, index) in faqs"
                :key="index"
                class="faq-box py-3"
              >
                <div
                  class="question d-flex justify-content-between align-items-center text-left"
                  @click="toggleCollapse(index)"
                  :data-bs-toggle="`collapse-${index}`"
                  :data-bs-target="`#faq-${index}`"
                  :aria-expanded="faq.expanded"
                >
                  <span class="text-left pb-1">{{ faq.question }}</span>
                  <i v-if="!faq.expanded" class="far fa-plus-square"></i>
                  <i v-else class="far fa-minus-square"></i>
                </div>
                <div
                  :id="`faq-${index}`"
                  class="collapse"
                  :class="{ show: faq.expanded }"
                >
                  <p class="text-start text-break">{{ faq.answer }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 col-sm-12">
            <div class="backgroundgreen2"></div>
          </div>
        </div>
        <div class="row increase">
          <div class="col-12 col-lg-3"></div>
          <div class="col-12 col-lg-3">
            <div class="text-justify pt-5">
              <p class="faq-text px-md-0 px-3">
                If your questions remain <br />unanswered, please feel free<br />
                to contact us for assistance
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-2"></div>
        </div>
        <!-- <div class="row">
            <div class="col-12 col-lg-5 "></div>
            <div class="col-12 col-lg-3 bg-image"></div>
            <div class="col-12 col-lg-4 "></div>
        </div> -->
        <div class="row">
          <div class="col-12 col-lg-5 col-md-3 col-sm-4"></div>
          <div class="col-12 col-lg-4 col-md-5 col-sm-4">
            <div class="bg-image px-md-0 px-3">
              <img
                src="@/assets/arrow.png"
                alt="Arrow"
                class="img-fluid faq-arrow"
              />
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-4 col-sm-4"></div>
        </div>
      </div>
    </section>
    <!-- end of FAQ-section -->

    <!-- contact-section -->
    <section
      v-if="
        $route.path !== '/terms&conditions' &&
        $route.path !== '/privacy-policy' &&
        $route.path !== '/initiate-collaboration'
      "
      class="contact-section py-5"
    >
      <div class="container pb-md-5">
        <div class="row flex-wrap-reverse">
          <div
            class="col-md-6 d-flex align-items-center justify-content-center"
          >
            <div class="form-cover">
              <form v-on:submit.prevent="onSubmitModal()" id="conform">
                <fieldset>
                  <div class="text mb-3">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Name</label
                    >
                    <input
                      v-model="username"
                      type="text"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your name"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <div class="text mb-3">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Email Address</label
                    >
                    <input
                      v-model="email"
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your email address"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <div class="text mb-3">
                    <label for="exampleFormControlInput1" class="form-label"
                      >Mobile Number</label
                    >
                    <input
                      v-model="phone"
                      type="text"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your mobile number"
                      @keypress="restrictChars($event)"
                      maxlength="10"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <div class="text mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label"
                      >Message</label
                    >
                    <textarea
                      v-model="message"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Write a message"
                      autocomplete="off"
                    ></textarea>
                  </div>
                  <div class="pt-2">
                    <button
                      type="submit"
                      class="submitBtn btn w-100 text-uppercase"
                    >
                      Submit
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          <div
            class="col-md-6 d-flex flex-column align-items-center justify-content-center con"
          >
            <div class="contact-us-img">
              <h1 class="d-none d-md-block heading">
                Reach Out to Elevate <br />Your Tech Journey!...
              </h1>
              <h1 class="text-center d-md-none heading">
                Reach Out to Elevate <br />Your Tech Journey!...
              </h1>

              <div class="pt-2">
                <img
                  src="@/assets/contact-img.png"
                  alt=""
                  class="contact-img img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end of contact-section -->

    <!-- new footer -->

    <div
      class="container-fluid px-5 py-4 text-left px-md-5"
      style="background: #000"
    >
      <section class="px-md-5">
        <div class="container-fluid text-md-start mt-5">
          <!-- Grid row -->
          <div class="row mt-3 pb-5">
            <!-- Grid column -->
            <div class="col-md-4 px-3 mb-3">
              <!-- Content -->
              <h6 class="text-uppercase fw-bold mb-4">
                <img src="@/assets/footer/footerlogo.png" alt="" />
              </h6>
              <p class="text-light">
                Best Digital Marketing and Mobile Application <br />
                Development Company in India.
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="footer-links col-md-2 px-2 text-light mb-3">
              <!-- Links -->
              <h3 class="footer-head mb-4">Quick Links</h3>
              <p>
                <a href="/" class="text-reset">Home</a>
              </p>
              <p>
                <a href="https://insites.digitalspike.co/" class="text-reset"
                  >Insights</a
                >
              </p>
              <p>
                <a href="/career" class="text-reset">Career</a>
              </p>
              <p>
                <a href="/about" class="text-reset">About Us</a>
              </p>
              <p>
                <a href="/contact" class="text-reset">Contact us</a>
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="footer-links col-md-3 px-2 text-light mb-3">
              <!-- Links -->
              <h3 class="footer-head mb-4">Services</h3>
              <p>
                <a href="/digital_marketing" class="text-reset"
                  >Digital Marketing
                </a>
              </p>
              <p>
                <a href="/website_development" class="text-reset"
                  >Website Developments
                </a>
              </p>
              <p>
                <a href="/web_application" class="text-reset"
                  >Web Application Development
                </a>
              </p>
              <p>
                <a href="/mobile_application" class="text-reset"
                  >Mobile Application Development
                </a>
              </p>
              <p>
                <a href="/ui_ux" class="text-reset">UI/UX Design </a>
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div
              class="col-md-3 px-3 text-light d-flex align-items-start justify-content-end flex-column py-3"
            >
              <h4 class="footer-head">Subscribe</h4>
              <div class="pb-3">
                <div
                  class="newsletter js-rollover d-flex flex-column align-items-md-start justify-content-md-start align-items-center justify-content-center"
                >
                  <form
                    class="newsletter-form"
                    name="submit-to-google-sheet"
                    id="newsform2"
                  >
                    <input
                      type="email"
                      name="Emails"
                      placeholder="Enter your email"
                      autocomplete="off"
                      required
                      style="background: #dbe2ff33; color: #fff !important"
                    />
                    <button
                      type="submit"
                      class="button"
                      style="
                        background: linear-gradient(
                          to right,
                          #2785ff 0%,
                          #73e386 40%
                        );
                        color: #000;
                      "
                    >
                      Subscribe
                    </button>
                    <div
                      class="modal fade"
                      id="email-modal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-body">
                            <div>
                              <section
                                class="clock p-1 d-flex align-items-center justify-content-center"
                              >
                                <div
                                  class="p-4"
                                  style="width: 25rem; border-radius: 20px"
                                >
                                  <div
                                    class="card-body d-flex flex-column align-items-center justify-content-center"
                                  >
                                    <div class="py-3 px-2">
                                      <img src="Group 136.png" alt="" />
                                    </div>
                                    <h1
                                      class="verify-btn py-2 lh-sm mb-0 text-center"
                                    >
                                      Grateful for Your Contact!
                                    </h1>
                                    <div class="pb-2 color">
                                      <p class="text-center">
                                        Thank You for Reaching Out to Us.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-md-start">
          <div class="row text-light">
            <div>
              <h1 class="footer-head mb-3">Office</h1>
            </div>

            <div class="col-md-4 footer-links mb-3">
              <h3 class="footer-head mb-4">Mysore</h3>
              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset"
                    ><img src="@/assets/footer/marker (8) 2.png" alt=""
                  /></a>
                </div>
                <div class="ps-3">
                  <a href="#"
                    >#392 1st Floor, Jnana Marga, Siddartha Nagar br
                       1st Stage,  Mysore -570011</a
                  >
                </div>
              </div>

              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset">
                    <img src="@/assets/footer/phone-call (7) 1.png" alt=""
                  /></a>
                </div>
                <div class="ps-3">
                  <a href="#"> +91 90089 83396</a>
                </div>
              </div>

              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset"
                    ><img src="@/assets/footer/mailbox (1) 1.png" alt=""
                  /></a>
                </div>
                <div class="ps-3">
                  <a href="#">hello@digitalspike.co</a>
                </div>
              </div>
            </div>

            <div class="col-md-4 footer-links mb-3">
              <h3 class="footer-head mb-4">Banglore</h3>

              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset">
                    <img src="@/assets/footer/marker (8) 2.png" alt=""
                  /></a>
                </div>
                <div class="ps-3">
                  <a href="#"
                    >MyHQ  #1207/343  &  1207/1/343/1, 9th Main, <br> 7th
                    Sector, HSRLayout, Banglore - 560102</a
                  >
                </div>
              </div>

              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset">
                    <img src="@/assets/footer/phone-call (7) 1.png" alt=""
                  /></a>
                </div>
                <div class="ps-3">
                  <a href="#">+91 90089 83396</a>
                </div>
              </div>

              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset">
                    <img src="@/assets/footer/mailbox (1) 1.png" alt=""
                  /></a>
                </div>
                <div class="ps-3">
                  <a href="#">hello@digitalspike.co</a>
                </div>
              </div>
            </div>

            <div class="col-md-4 footer-links mb-3">
              <h3 class="footer-head mb-4">Dubai</h3>

              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset">
                    <img src="@/assets/footer/marker (8) 2.png" alt="" />
                  </a>
                </div>
                <div class="ps-3">
                  <a href="#">
                    # 302, Trafalgar TowerCBD7,<br>
                    International city , Dubai, UAE.</a
                  >
                </div>
              </div>

              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset">
                    <img src="@/assets/footer/phone-call (7) 1.png" alt=""
                  /></a>
                </div>
                <div class="ps-3">
                  <a href="#"> +91 90089 83396 </a>
                </div>
              </div>

              <div class="d-flex mb-3">
                <div>
                  <a href="#!" class="text-reset">
                    <img src="@/assets/footer/mailbox (1) 1.png" alt=""
                  /></a>
                </div>
                <div class="ps-3">
                  <a href="#">hello@digitalspike.co </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-4">
          <div class="row">
            <div class="col-xl-5 col-md-12 text-white mb-3">
              <div class="text-center">
                © 2022 Digital Spike Technologies. All Rights Reserved
              </div>
            </div>
            <div
              class="col-xl-2 col-md-4 col-6 d-flex align-items-center justify-content-center text-white mb-3"
            >
              <a href="/terms&conditions">
                <div class="text-center">Terms & Conditions</div>
              </a>
            </div>
            <div class="col-xl-2 col-md-4 col-6 text-white">
              <a href="/privacy-policy">
                <div class="text-center mb-3">Privacy Policy</div>
              </a>
            </div>
            <div class="col-xl-3 col-md-4 mb-3 text-white">
              <div
                class="d-flex align-items-center justify-content-center text-white"
              >
                <div class="pe-2">
                  <a
                    href="https://www.facebook.com/profile.php?id=100083258952954"
                    class="me-4 text-reset"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </div>
                <div class="pe-2">
                  <a
                    href="https://www.instagram.com/digital_spike/"
                    class="me-4 text-reset"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
                <div class="pe-2">
                  <a
                    href="https://www.linkedin.com/company/digital-spike-technologies/"
                    class="me-4 text-reset"
                  >
                    <i class="fab fa-linkedin"></i>
                  </a>
                </div>
                <div class="pe-2">
                  <a
                    href="https://twitter.com/DigitalSpikeTe1"
                    class="me-4 text-reset"
                  >
                    <i class="fa-brands fa-x-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- new  end of footer -->
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "App",
  data() {
    return {
      username: "",
      email: "",
      phone: "",
      message: "",
      faqs: [
        {
          question: "How do I get started with your website design services? ",
          answer:
            " Contact us on www.digitalspike.co to schedule a consultation and get a free quote for your project.",
          expanded: false,
        },
        {
          question:
            "How do your website development services benefit my business?",
          answer:
            "Enhance online visibility, credibility, and streamline business processes, converting visitors into customers.",
          expanded: false,
        },
        {
          question: "Is it possible to later modify and update an application?",
          answer:
            "Yes, our provision for scalability allows easy feature enhancements and functionality extensions.",
          expanded: false,
        },
        {
          question: "What digital marketing services do you offer?",
          answer:
            "Our services include SEO, social media management, content marketing, PPC advertising, and email campaigns to amplify your online presence.",
          expanded: false,
        },
        {
          question:
            "How soon can I see results from digital marketing efforts?",
          answer:
            "Results vary, but our strategies aim for a balance of short-term gains and sustained long-term growth. It's a continuous process.",
          expanded: false,
        },
        {
          question: "Will my business benefit from social media marketing?",
          answer:
            "Absolutely. Social media marketing enhances brand visibility, engagement, and customer loyalty, driving business growth.",
          expanded: false,
        },
        // Add more FAQ items as needed
      ],
    };
  },
  methods: {
    restrictChars: function ($event) {
      if (
        $event.charCode === 0 ||
        /\d/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    onSubmit() {
      axios
        .post(
          "https://digitalspike.co/enquiry/enquiry.php",
          {
            username: this.username,
            email: this.email,
            phone: this.phone,
            message: this.message,
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
          console.log(response);
          $("#myForm")[0].reset();
          $("#exampleModals").modal("hide");
        })
        .then(() => {
          $("#email-modal").modal("show");
          setTimeout(function () {
            $("#email-modal").modal("hide");
          }, 2000);
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
    onSubmitModal() {
      axios
        .post(
          "https://digitalspike.co/enquiry/ads_enquiry.php",
          {
            username: this.username,
            email: this.email,
            phone: this.phone,
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
          console.log(response);
          $("#conform")[0].reset();
        })
        .then(() => {
          $("#email-modal").modal("show");
          setTimeout(function () {
            $("#email-modal").modal("hide");
          }, 2000);
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
    toggleCollapse(index) {
      // Close all FAQs
      this.faqs.forEach((faq, i) => {
        if (i !== index) {
          faq.expanded = false;
        }
      });

      // Toggle the selected FAQ
      this.faqs[index].expanded = !this.faqs[index].expanded;
    },
    slide(direction) {
      const tabsContainer = this.$refs.tabsContainer;

      if (tabsContainer) {
        const scrollAmount = 300; // Adjust the scroll amount as needed

        if (direction === "left") {
          tabsContainer.scrollLeft -= scrollAmount;
        } else if (direction === "right") {
          tabsContainer.scrollLeft += scrollAmount;
        }
      }
    },
  },
  mounted() {
    jQuery(function ($) {
      var path = window.location.href;
      $("ul a").each(function () {
        if (this.href === path) {
          $(this).addClass("active");
        }
      });
    });

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbzYiEqkkc1Nd-DlDPKpUaGTpYklzUSm16NtiH_FSBYsCwYKyWozMPJJRQZArp0XEf9atA/exec";
    const form = document.forms["submit-to-google-sheet"];

    form.addEventListener("submit", (e) => {
      e.preventDefault();
      fetch(scriptURL, { method: "POST", body: new FormData(form) })
        .then(
          (response) => console.log("Success!", response),
          $("#newsform2")[0].reset(),
          $("#email-modal").modal("show"),
          $(".modal-backdrop").hide(),

          setTimeout(function () {
            $("#email-modal").modal("hide");
          }, 2000)
        )
        .catch((error) => console.error("Error!", error.message));
    });

    $(window).on("load", function () {
      setTimeout(function () {
        $("#exampleModals").modal("show");
      }, 3000);
    });
  },
};
</script>

<style scoped>
.main {
  overflow-x: hidden;
}

/* navbar-section */
.logo {
  width: 154px;
  height: 64px;
}

nav .nav-link {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.active {
  color: rgba(39, 133, 255, 1) !important;
}

nav .download {
  background: rgba(13, 31, 15, 1);
}

nav .btn-txt {
  background: linear-gradient(
    90deg,
    rgba(51, 146, 238, 1) 2%,
    rgba(115, 227, 133, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}

nav .btn-txt:hover {
  background: linear-gradient(
    90deg,
    rgba(115, 227, 133, 1) 2%,
    rgba(51, 146, 238, 1) 73%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* transition: all 60s linear; */
}

@media (max-width: 767.98px) {
  .d {
    padding-top: 14px;
  }
}

.navbar-toggler:focus,
.btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* .navbar-toggler,.btn-close {
      border: none;
    } */
/* sub-navbar-section */
.subnav-section {
  margin: 0px 60px;
}

.subnav-class a {
  color: rgba(1, 13, 38, 1);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.subnav-class header .tabs {
  display: flex;
  overflow-y: auto;
}

.subnav-class header .tabs a img {
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.subnav-class header .tabs a {
  display: block;
  padding: 0px 13px 13px 28px;
  font-weight: 900;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
  cursor: pointer;
}

.subnav-class .tabs::-webkit-scrollbar {
  height: 0;
}

.subnav-class header .tabs a.router-link-active {
  border-bottom: 2px solid #73e386;
}

@media screen and (max-width: 992px) {
  .subnav-section {
    margin: 0px 0px;
  }
}

/* faq-section */
.faq-design {
  background-image: url("~@/assets/faq-bg.png");
  background-repeat: no-repeat;
  background-position: left !important;
}

.faq-design .question {
  font-weight: 700;
  cursor: pointer;
}

.faq-design .heading {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.faq-design .faq-box:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.far.fa-plus-square,
.far.fa-minus-square {
  color: #2785ff;
}

.faq-arrow {
  max-width: 200px;
}

.faq-design .question {
  color: rgba(16, 16, 16, 1) !important;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: large;
}

.faq-design .collapse {
  color: rgba(16, 16, 16, 1) !important;
  font-family: "Mulish";
  font-weight: 400;
}

.faq-design .faq-text {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-family: "Open Sans";
}

@media (max-width: 767.98px) {
  .faq-box {
    padding: 0px 16px;
  }
}

/* contact-section */
.contact-section {
  background-image: url("~@/assets/contact-bg.png");
  background-repeat: no-repeat;
  background-position: right !important;
  color: #000;
  font-family: "Exo", sans-serif;
}

.contact-section .contact-links {
  font-weight: 600;
}

.contact-section .contact-img {
  max-width: 300px;
}

.contact-section .form-cover {
  width: 86%;
}

.contact-section .form-control {
  font-size: 15px !important;
  color: #858585;
  font-family: Mulish;
  border: 1.5px solid #d1d3db80;
}

.contact-section .form-control:focus {
  box-shadow: none;
}

.contact-section .submitBtn {
  background: #2289fe;
  color: rgba(255, 255, 255, 1);
  border: none;
  font-family: "Montserrat";
  font-weight: 600;
}

.contact-section .submitBtn:hover {
  background: #2289fe;
  color: rgba(255, 255, 255, 1);
  border: none;
  font-family: "Montserrat";
  font-weight: 600;
}

.contact-section .heading {
  color: rgba(16, 16, 16, 1);
  font-family: "Montserrat";
  font-weight: 700;
}

.contact-section label {
  font-family: "Montserrat";
  font-weight: 400;
  color: rgba(18, 18, 18, 1);
}

.contact-section input {
  font-family: "Mulish";
  font-weight: 400;
  color: rgba(133, 133, 133, 1);
}

/* footer-section */
.footer .links-header {
  color: rgba(39, 133, 255, 1);
  font-family: "Montserrat";
  font-weight: 600;
}

.footer .links-item {
  color: rgba(1, 13, 38, 1);
  font-family: "Mulish";
  font-weight: 500;
}

.footer .copyright {
  color: rgba(1, 13, 38, 1);
  font-family: "Montserrat";
}

.footer .copyright-conditions {
  color: rgba(0, 52, 109, 1);
  font-family: "Montserrat";
}

@media (max-width: 767.98px) {
  .contact-section {
    background-position: center !important;
  }
}

a {
  text-decoration: none;
}

/* floating-section */
.floating-icons .icons {
  top: 94%;
  right: 20px;
  transform: translateY(-50%);
  position: fixed;
  z-index: 100;
}

.floating-icons .icons ul {
  padding: initial;
}

.floating-icons .icons ul li {
  height: 48px;
  width: 48px;
  border-radius: 30px;
  list-style-type: none;
  text-align: center;
  padding-top: 4px;
  color: #fff;
}

.floating-icons .whatsapp {
  background: #25d366;
  font-size: 24px;
}

/* popup-modal */
.popup-modal .submitBtn {
  background: #000;
  color: #ffffff;
}

.floating-icon .icons {
  /* top: 94%; */
  /* right: 20px; */
  /* transform: translateY(-50%);
    position: fixed; */
  z-index: 100;
}

.floating-icon .icons ul {
  padding: initial;
}

.floating-icon .icons ul li {
  height: 42px;
  width: 130px;
  border-radius: 10px;
  list-style-type: none;
  text-align: center;
  padding-top: 2px;
  color: #fff;
}

.floating-icon .whatsapp {
  background: #25d366;
  font-size: 24px;
}

.floating-icon .phone {
  background: #2289fe;
  font-size: 24px;
}

/* new changes */

.newsletter {
  position: relative;
  /* display: flex;
  align-items: start;
  justify-content: start; */
}

.newsletter .newsletter-form {
  position: relative;
  max-width: 355px;
  margin-top: 23px;
  z-index: 999;
}

.newsletter .newsletter-form input {
  height: 55px;
  width: 100%;
  padding: 20px 135px 17px 20px;
  border: none;
  border-radius: 10px;
  outline: none;
  background-color: #ffffff;
  color: rgba(133, 133, 133, 1);
  font-family: "Mulish";
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.newsletter .newsletter-form button {
  width: 120px;
  height: 38px;
  border-radius: 5px;
  background-color: #000;
  font-size: 0.875rem;
  line-height: 1;
  position: absolute;
  right: 9px;
  bottom: 9px;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-family: "Montserrat";
  font-weight: 600;
}
/* /new footer */
.footer-links a {
  text-decoration: none;
}

.footer-head {
  background: linear-gradient(90deg, #3392ee 2%, #73e385 5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

a {
  text-decoration: none;
  color: #fff;
}

.modal-body {
  background: url("@/assets/blogs/modalGreen.png"),
    url("@/assets/blogs/modalBlue.png");
  background-repeat: no-repeat;
  background-position: right, left;
}

.submitBtn:hover {
  color: #fff !important;
}

.modal .modal-content {
  border-radius: 20px !important;
}
</style>
