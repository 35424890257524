<template>
    <div>
    <section class="clock p-1 col-md-12 d-flex flex-column align-items-center justify-content-center">
      <div class="" style="width: 25rem;background:white;border-radius:20px">
        <div class="card-body d-flex flex-column align-items-center justify-content-center">
           <div class="py-3 px-2">
           <img src="@/assets/Group 136.png" alt="" /> 
          </div> 
          <h1 class="verify-btn  py-2 lh-sm mb-0 text-center" >
            Grateful for Your Contact!
          </h1>
          <div class="pb-2 color ">
          <p class="text-center">
            Thank You for Reaching Out to Us.
          </p>
          
          </div>
          
        </div>
      </div>
    </section>
  </div>
</template>

<script>
    export default {
        name:"GreatefullPopup"
    }
</script>

<style scoped>
.color{
   
   color: #8F9DAA;
    line-height: 4px !important;
    font-size: 80%;

}
.verify-btn{
   font-size: 150%;
}
.clock{
   background-color: #f9f9fc;
   border-color: #FFFFFF;
   
}

</style>