import { createRouter, createWebHistory } from 'vue-router'

import HomeComponent from '@/components/HomeComponent.vue'
import InsightComponent from '@/components/InsightComponent.vue'
import AboutComponent from '@/components/AboutComponent.vue'
import CareerComponent from '@/components/CareerComponent.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
import TermsConditions from '@/components/TermsConditions.vue'
import InitiateCollaboration from '@/components/InitiateCollaboration.vue'
import digitalmarketing from '@/components/TabComponents/digitalmarketing.vue'
import mobileapplication from '@/components/TabComponents/mobileapplication.vue'
import uiux from '@/components/TabComponents/uiux.vue'
import webapplication from '@/components/TabComponents/webapplication.vue'
import websitedevelopment from '@/components/TabComponents/websitedevelopment.vue'
import GreatefullPopup from '@/components/GreatefullPopup.vue'
GreatefullPopup

const routes = [
  
  {
    path: '/',
    name: 'home',
    component: HomeComponent
  },
  {
    path: '/insights',
    name: 'insights',
    component: InsightComponent
  },
  {
    path: '/about',
    name: 'about',
    component: AboutComponent
  },
  {
    path: '/career',
    name: 'career',
    component: CareerComponent
  },
  {
    path: '/initiate-collaboration',
    name: 'initiatecollaboration',
    component: InitiateCollaboration
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms&conditions',
    name: 'terms&conditions',
    component: TermsConditions
  },
  {
    path: '/digital_marketing',
    name: 'digitalmarketing',
    component: digitalmarketing
  },
  {
    path: '/mobile_application',
    name: 'mobileapplication',
    component: mobileapplication
  },
  {
    path: '/ui_ux',
    name: 'uiux',
    component: uiux
  },
  {
    path: '/web_application',
    name: 'webapplication',
    component: webapplication
  },
  {
    path: '/website_development',
    name: 'websitedevelopment',
    component: websitedevelopment
  },
  {
    path: '/greatefull-popup',
    name: 'greatefullpopup',
    component: GreatefullPopup
  },
]

const router = createRouter({
  base: process.env.VUE_APP_PUBLICPATH,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
